import React, { Fragment } from "react";
// import { Card, CardContent, Typography } from "@material-ui/core";
// import { PieChart } from "./PieChart";

export default function Reports(props) {
  return (
    <Fragment>
      {/* <Card variant="outlined" style={{ marginTop: "15%", marginLeft: "40%" }}>
    //   <CardContent>
    // <Typography variant="h4">Coming Soon...</Typography>
    //   </CardContent>
    // </Card> */}
      <iframe
        width="100%"
        height="100%"
        src="https://datastudio.google.com/embed/reporting/6d36faed-18d0-400c-8455-651779a2261e/page/EhniB"
        frameBorder="0"
        //   style="border:0"
        allowfullscreen
      >
      </iframe>
      {/* <iframe
        // style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
        width="100%"
        height="100%"
        src="https://charts.mongodb.com/charts-onboarding-aghic/embed/charts?id=2ffed3c8-8386-47f0-9319-ba71aaeb49de&autoRefresh=60&theme=light"
      ></iframe>
      <iframe
        //style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"
        width="100%"
        height="100%"
        src="https://charts.mongodb.com/charts-onboarding-aghic/embed/charts?id=0e22f23c-4de3-4462-a12b-48da0bf8e3b9&autoRefresh=60&theme=light"
      ></iframe> */}
      {/* <PieChart
        title="My First Pie Chart"
        labels={["Under 18", "18 to 54", "Above 54"]}
        datasets={[
          { data: [200, 300, 500], backgroundColor: ["red", "blue", "green"] },
        ]}
      /> */}
    </Fragment>
  );
}
