import { AppBar, Card, CircularProgress, Container, CssBaseline, Grid, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
//import { sendPostRequest } from '../Actions';
import { color } from '../assets/color';
import FileUpload from '../components/FileUpload';
import MessageScreen from '../components/MessageScreen';
//import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import { PowerSettingsNew } from "@material-ui/icons";
import SystemAlert from '../components/SystemAlert';
import Urls from '../Urls';
import Cookies from 'js-cookie';
import ProgressBar from '../components/ProgressBar';
import HamburgerMenu from './HamburgerMenu';

const SmsSender = (props) => {
    const [screen, setScreen] = useState(1);
    const [displayAlert, setDisplayAlert] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRecipients, setTotalRecipients] = useState(0);


    useEffect(() => {
        // setScreen(1)
        // checkUpload()
    }, []);

    const checkUpload = (total) => {
        //check which component to render
        fetch(Urls.validateCsv, { method: "post" })
            .then(response => response.json())
            .then(responseJSON => {
                setLoading(false);
                total>0 ? setTotalRecipients(total) : setTotalRecipients(0)
                responseJSON.status ? setScreen(2) : setScreen(1)
            }).catch(err => {
                setLoading(false);
                setAlertMessage('Something went wrong, please check your network.')
            })
    }

    const handleLogout = () => {
        Cookies.remove("userID", { expires: 30 });
        Cookies.remove("userRole", { expires: 30 });
        props.checkLogin();
    }

    const setAlertMessage = (message) => {
        setDisplayAlert(true)
        setErrorMessage(message)
    }

    const handleAlert = () => {
        setDisplayAlert(false)
        setErrorMessage("")
        window.location.reload();
    };

    let component = screen == 1 ? <></> : <MessageScreen setAlertMessage={setAlertMessage} setLoading={setLoading} checkUpload={checkUpload} totalRecipients={totalRecipients} />

    return (
        <Fragment>
            <CssBaseline />
            <AppBar position="fixed" style={{ backgroundColor: color.red, width: "100%" }}>
                <Toolbar>
                    <Typography variant="h4">SMS Sender</Typography>
                    <IconButton edge={'end'} style={{ marginLeft: "82%" }} color={'inherit'} >
                        
                            <HamburgerMenu handleLogout={handleLogout}/>

                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid
                container
                direction={'column'}
                justify="space-evenly"
                alignItems="stretch"
                style={{ padding: "11%" }}
                spacing={6}
            >
                <Grid item xs={12} >
                    <Typography variant={'h3'} color={"primary"}>Send Bulk SMS</Typography>
                    <Typography variant={'body1'} color={"textSecondary"}>1. Upload .csv file with 'Name' and 'Phone_Number' columns.</Typography>
                    <Typography variant={'body1'} color={"textSecondary"}>2. Template is available for Download in Menu.</Typography>
                    <Typography variant={'body1'} color={"textSecondary"}>3. Type the message that you want to send.</Typography>
                    <Typography variant={'body1'} color={"textSecondary"}>4. Voila! The personalized SMS is sent to all the mentioned candidates.</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                    <FileUpload setAlertMessage={setAlertMessage} setLoading={setLoading} checkUpload={checkUpload} />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                    {loading ? <ProgressBar /> : component}
                </Grid>


                {/* <Grid item xs={12} >
                    <Pagination 
                        count={2}
                        style={{color:color.orange}}
                        disabled
                    />
                </Grid> */}

            </Grid>

            <SystemAlert
                open={displayAlert}
                handleAlert={handleAlert}
                message={errorMessage}
            />
        </Fragment>
    );

}

export default SmsSender;