import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, TextField } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import SendIcon from '@material-ui/icons/Send'
import ClearIcon from '@material-ui/icons/Clear';
import { sendPostRequest } from '../Actions';
import { color } from "../assets/color";
import SystemAlert from "./SystemAlert";
import Urls from '../Urls';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";

export default function SendNotification(props) {
    const style = useStyles();

    const [selectedCandidate, setSelectedCandidate] = React.useState("Confirmed Candidates");
    const [displayAlert, setDisplayAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();

    //To Add Link
    const [textFieldList, setTextFieldList] = React.useState([]);
    const [textFieldIndex, setTextFieldIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const handleClickOpenConfirmation = () => {
        setOpenConfirmation(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenConfirmation(false);
    };

    const addCustomOptions = () => {
        const indexDetailsTextField = textFieldIndex + 1;
        setTextFieldIndex(indexDetailsTextField);
        setTextFieldList(() => { return [...textFieldList, { index: indexDetailsTextField, name: "", selected: true }] });
    }
    //to delete the link text field
    const ClearTextField = (event, index) => {
        setTextFieldList(textFieldList.filter(f => f.index !== index));
    }

    const handleEmptyMessage = () => {
        if (title.trim() !== "" && message.trim() !== "") {
            handleClickOpenConfirmation();
        }
        else {
            handleClickOpen();
            setErrorMessage("Please enter the title and message");
            setDisplayAlert(true);
        }
    }

    const handleSendMessage = () => {
        //console.log("SendMessage ")
        let urlDetail = "";
        if (textFieldList.length > 0) {
            let validTextFieldList = textFieldList.filter(f => f.name.trim() !== "");
            if (validTextFieldList.length > 0) {
                urlDetail = validTextFieldList.map(m => m.name).join(',');
            }
        }
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            timer.current = setTimeout(() => {
                setSuccess(true);
                setLoading(false);
            }, 2000);
        }
        let data = {
            "user_id": props.userID,
            "candidate_type": selectedCandidate,
            "message": {
                "title": title,
                "body": message,
                "url": urlDetail
            }
        }
        //console.log(data)
        sendPostRequest(Urls.sendMessage, data).then(response => {
            if (!response) {
                setErrorMessage("Failed to Send Message, please try again")
                setDisplayAlert(true)
            } else {
                //console.log(response)
                setErrorMessage("Message sent succesfully")
                setDisplayAlert(true)
                setTitle("");
                setMessage("");
                setTextFieldList([]);
                setTextFieldIndex(0);
            }
        }).catch(err => {
            //console.log("Error:", err);
            setErrorMessage("Please check the network and try again later.");
            setDisplayAlert(true);
        })
        handleClose()
    }

    const boxClick = (event, type) => {
        setSelectedCandidate(type);
        setTitle("");
        setMessage("");
        setTextFieldList([]);
        setTextFieldIndex(0);
    }

    const handleAlert = () => {
        setDisplayAlert(!displayAlert)
        setErrorMessage("")
    }
    const handleTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleMessage = (event) => {
        setMessage(event.target.value)
    }
    const handleLink = (event, index) => {
        textFieldList.map(m => {
            if (m.index === index) {
                m.name = event.target.value;
            }
        })
    }

    // const handleRefreshPage = () =>{
    //     setTitle("");
    //     setMessage("");
    //     setTextFieldList([]);
    //     setTextFieldIndex(0);
    // }

    return (
        <Fragment>
            {/* <Grid container className={style.refreshIconGrid}>
                <Button
                    // className={style.refreshIconButton}
                    onClick={handleRefreshPage}
                >
                    <RefreshIcon
                        className={style.refreshIcon}
                    >
                    </RefreshIcon>
                </Button>
            </Grid> */}
            <Button
                variant="contained"
                onClick={event => boxClick(event, "Confirmed Candidates")}
                className={style.candidateButton}
                style={{ backgroundColor: (selectedCandidate === 'Confirmed Candidates' ? color.orange : color.blue) }}
            >
                Confirmed Candidates
            </Button>
            <Button
                variant="contained"
                onClick={event => boxClick(event, 'Not Joined Candidates')}
                className={style.candidateButton}
                style={{ backgroundColor: (selectedCandidate === 'Not Joined Candidates' ? color.orange : color.blue) }}
            >
                Not Joined Candidates
                    </Button>
            <Button
                variant="contained"
                onClick={event => boxClick(event, 'Active Candidates')}
                className={style.candidateButton}
                style={{ backgroundColor: (selectedCandidate === 'Active Candidates' ? color.orange : color.blue) }}
            >
                Active Candidates
                    </Button>
            <Button
                variant="contained"
                onClick={event => boxClick(event, 'Benched Candidates')}
                className={style.candidateButton}
                style={{ backgroundColor: (selectedCandidate === 'Benched Candidates' ? color.orange : color.blue) }}
            >
                Benched Candidates
                    </Button>
            <Button
                variant="contained"
                onClick={event => boxClick(event, 'Alumni')}
                className={style.candidateButton}
                style={{ backgroundColor: (selectedCandidate === 'Alumni' ? color.orange : color.blue) }}
            >
                Alumni
                    </Button>
            {/* <Box
                boxShadow={7}
                onClick={event => boxClick(event, "Confirmed Candidates")}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Confirmed Candidates' ? color.orange : color.blue) }}
            >
                Confirmed Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Not Joined Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Not Joined Candidates' ? color.orange : color.blue) }}
            >
                Absconded Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Active Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Active Candidates' ? color.orange : color.blue) }}
            >
                Active Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Benched Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Benched Candidates' ? color.orange : color.blue) }}
            >
                Benched Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Alumni')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Alumni' ? color.orange : color.blue) }}
            >
                Alumni
                    </Box> */}
            <Grid container className={style.container}>
                <Grid item xs={12}>
                    <Typography className={style.textField} variant="h6">To: {selectedCandidate}</Typography><br />
                </Grid>
                <Grid item xs={12}>
                    <TextField className={style.textField} variant="outlined" label="Title" onChange={handleTitle} value={title} />
                </Grid>
                <Grid item xs={12}>
                    <br />
                    <TextField
                        className={style.textField}
                        onChange={handleMessage}
                        multiline
                        rows={5}
                        variant="outlined"
                        label="Message"
                        value={message}
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        textFieldList.map(function (option, i) {
                            return <label key={i}>
                                <TextField
                                    className={style.linkTextField} variant="outlined" label="Link"
                                    onChange={event => handleLink(event, option.index)}
                                />
                                <ClearIcon
                                    onClick={event => ClearTextField(event, option.index)}
                                    className={style.closeButton} />
                            </label>
                        })
                    }
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                    <br />
                    <Button
                        onClick={addCustomOptions}
                        variant="contained"
                        className={style.linkButton}
                        startIcon={<LinkIcon />}
                    >
                        Add a Link</Button>
                </Grid>
                <Grid item xs={3}>
                    <br />
                    <Button
                        onClick={handleEmptyMessage}
                        variant="contained"
                        className={style.button}
                        startIcon={<SendIcon />}
                    >
                        Send Message</Button>
                </Grid>
            </Grid>
            <SystemAlert open={displayAlert} handleAlert={handleAlert} message={errorMessage} />
            <Dialog open={openConfirmation} onClose={handleClose} >
                <DialogContent>
                    <Typography gutterBottom>Are you sure you want to send this message to all {selectedCandidate}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSendMessage} className={style.blueButton}>
                        Yes
                    </Button>
                    <Button autoFocus onClick={handleClose} className={style.orangeButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 14,
    },
    textField: {
        width: "100%"
    },
    linkTextField: {
        width: "97%",
        marginTop: "5%"
    },
    linkButton: {
        margin: theme.spacing(1),
        width: "90%",
        color: color.white,
        backgroundColor: color.blue,
        "&:hover": {
            backgroundColor: color.blue
        }
    },
    button: {
        margin: theme.spacing(1),
        width: "90%",
        color: color.white,
        backgroundColor: color.orange,
        "&:hover": {
            backgroundColor: color.orange,
        }
    },
    closeButton: {
        marginTop: "6.5%"
    },
    box: {
        display: "flex",
        color: color.white,
        width: "16.5%",
        height: "10vh",
        marginLeft: "3%",
        padding: 3,
        justifyContentItems: "center",
        alignItems: "center",
        justifyContent: "center"
    },
    candidateButton: {
        display: "flex",
        color: color.white,
        width: "17%",
        height: "10vh",
        marginLeft: "3%",
        padding: 3,
        alignItems: "center",//TODO flex-start
        justifyContent: "center",//TODO flex-start    
        textTransform: "none",
    },
    container: {
        marginLeft: "3%",
        marginTop: "3%",
        width: "95%",
    },
    // Progress bar while clicking button
    buttonProgress: {
        color: color.red,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    blueButton: {
        color: color.blue,
        padding: theme.spacing(1),
    },
    orangeButton: {
        color: color.orange
    },
    refreshIconGrid: {
        display: "flex",
        alignContent: "flex-start",
        marginTop: "2%",
        marginLeft: "95%",
        marginBottom: "1%",
        width: "4%",
        boxShadow: 5,

    },
    // refreshIcon: {
    //     fontSize: "45px",
    // },
    // refreshIconButton:
    // {
    //     backgroundColor: color.white,
    // },
}))

