import React, { Fragment, Component } from "react";
import { Button, Grid, Typography, Container, Card, CssBaseline, AppBar, Toolbar } from '@material-ui/core';
import { color } from "../assets/color";
import MessageScreen from "./MessageScreen";
import SystemAlert from "./SystemAlert";
import CSVFileValidator from 'csv-file-validator'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import csvFileValidator from "csv-file-validator";
import Urls from "../Urls";
import { FormatBold } from "@material-ui/icons";

export default class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileToUpload: undefined,
            uploadSuccess: undefined,
            error: undefined,
            content: <></>,
            displayAlert: false,
            errorMessage: "",
            totalRecipients: 0 
        };
    }

    csvFileValidatorConfig = {
        headers: [
            {
                name: 'Name',
                required: true,
                inputName:'Employee Name',
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required at row-${rowNumber}, column-${columnNumber}\n`
                },
                headerError: function (headerName) {
                    return `Column Header ${headerName} is incorrect\n`
                },
            },
            {
                name: 'Phone_Number',
                required: true,
                inputName: 'Phone Number',
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required at row-${rowNumber}, column-${columnNumber}\n`
                },
                headerError: function (headerName) {
                    return `Column Header ${headerName} is incorrect\n`
                },
                validate: function(number ) {
                    return (number.length == 10) ? true : false
                },
                validateError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is not valid at row-${rowNumber}, column-${columnNumber} column, only 10 digit numbers are valid\n`
                }
            },
        ],
        isHeaderNameOptional: false

    }

    validateFile() {
        this.props.setLoading(true)
        // console.log('csvFile: ', this.state.fileToUpload)
        csvFileValidator(this.state.fileToUpload, this.csvFileValidatorConfig)
            .then((csvData) => {
                if (csvData.inValidMessages.length != 0) {
                    this.props.setLoading(false)
                    this.props.setAlertMessage(csvData.inValidMessages)
                    // window.location.reload();
                    // console.log('Invalid csv file ', csvData.inValidMessages)
                    // this.setState({ fileToUpload: undefined });
                    // input = document.getElementsByClassName('form-control-file').target.value;
                    // input.target.value && input.target.value == null; 
                } else {
                    console.log('CSV Data Length: ',csvData.data.length)
                    this.setState({totalRecipients: csvData.data.length})
                    this.uploadFile()
                }
            })
            .catch(err => {
                // console.log('Could not validate csv: ', err)
                this.props.setLoading(false)
                this.props.setAlertMessage("Could not validate csv, please check the file and re-upload")
                // this.setState({ fileToUpload: undefined });
            })
    }

    uploadFile(){ 

        var formData = new FormData(document.getElementById("uploadForm"));
        fetch(Urls.csvUpload,{
            method: 'POST',
            body: formData
        }).then((response) => {
            console.log(response)
            return response.json()
        }).then((responseJson) => {
            // console.log('Response: ', responseJson)
            if (responseJson.status) {
                fetch(Urls.validateCsv,{method: "post"})
                .then(response=>response.json())
                .then(responseJSON=>{
                    this.props.setLoading(false)
                    // console.log(responseJSON)
                    responseJSON.status ? this.props.checkUpload(this.state.totalRecipients) : this.props.setAlertMessage('File not uploaded')
                }).catch(err=>{
                    this.props.setLoading(false)
                    this.props.setAlertMessage('Something went wrong, please check your network.') 
                })
            } else {
                this.props.setLoading(false)
                this.props.setAlertMessage(responseJson.message)
            }

        }).catch((err) => {
            // console.log('Error while uploading file: ', err)
            this.props.setLoading(false)
            this.props.setAlertMessage("Something went wrong, try again.")
        })
    

    }

    next() {
        // console.log("next")
        if (this.state.fileToUpload) {
            this.setState({
                errorMessage: "Something went wrong. Please try again later.",
                displayAlert: true,
            });
        } else {
            this.setState({
                content: (
                    <MessageScreen totalRecipients={this.state.totalRecipients} />
                ),
            });
        }
    }

    render() {

        
        return (
            <Fragment>
                <Card style={{ justifyContentItems: "center", alignItems: "center", justifyContent: "center" }}>
                    <Grid container spacing={3} style={{ padding: '5%' }}>
                        <Grid item xs={12}>
                            <Typography variant="h5"><b>Upload CSV File</b></Typography>
                        </Grid>
                        <Grid itemx s={12}>
                            <form
                                ref='uploadForm'
                                id='uploadForm'
                                method='post'
                                encType="multipart/form-data"
                            >
                                <input
                                    type="file"
                                    name='file'
                                    // className="form-control-file"
                                    style={{color: color.blue}}
                                    accept='.csv'
                                    restrictions={{
                                        maxFileSize: 10000
                                    }}
                                    onChange={e => {
                                        this.setState({
                                            fileToUpload: e.target.files[0]
                                        });
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}} >
                                <Button
                                    style={{
                                        color: color.white,
                                        backgroundColor: color.blue,
                                    }}
                                    variant={"contained"}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => { this.validateFile() }}
                                >
                                    Upload
                                </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Fragment>
        );
    }
}