import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Grid,
} from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { color } from "../assets/color";
import Moment from "moment";
import SystemAlert from "./SystemAlert";
import MomentUtils from "@date-io/moment";
import Urls from "../Urls";
// import ProgressBar from "./ProgressBar";
import { sendPostRequest } from "../Actions";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useEffect } from "react";

export default function CandidateModal(props) {
  const style = useStyles();

  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(
    Moment(new Date(props.candidate.start_date)).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    Moment(new Date(props.candidate.actual_end_date)).format("MM/DD/YYYY")
  );
  // const [progressBar, setProgressBar] = useState(true);
  // const [isCompareDate, setIsCompareDate] = useState(false);
  // const [startDatePicker, setStartDatePicker] = useState(false);
  // const [endDatePicker, setEndDatePicker] = useState(false);
  // const [open, setOpen] = React.useState(false);
  const [jobTitle, setJobTitle] = React.useState(props.candidate.job_title);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  const validateCandidate = (candidate) => {
    //console.log(candidate.start_date)
    if (candidate.start_date === null || (candidate.actual_end_date === null && candidate.tax_terms != "Full Time")) {
      return false;
    }
    return true;
  };

  const handleClickOpen = () => {
    setOpenConfirmation(true);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  const handleMarkAsActive = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleMarkActive(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsAbsconded = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleMarkAbsconded(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleRemoveCandidate = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleMarkAlumni(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsAbscondedToConfirmed = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleAbscondedToConfirmed(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsAbscondedToBlackListed = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleAbscondedToBlackListed(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsActiveToConfirmed = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleActiveToConfirmed(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsAlumniToActive = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleAlumniToActive(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleMarkAsBenchedtoActive = (event, candidate) => {
    if (validateCandidate(candidate)) {
      props.handleBenchedtoActive(event, candidate);
      props.handleClose();
    } else {
      setErrorMessage(
        "Please approve candidate's start/end date from the Approval Center"
      );
      setDisplayAlert(true);
    }
  };

  const handleSetJobTitle = (event) => {
    setJobTitle(event.target.value);
  };

  const handleStartDate = (event) => {
    setStartDate(Moment(event._d).format("MM/DD/YYYY"));
  };

  const handleEndDate = (event) => {
    setEndDate(Moment(event._d).format("MM/DD/YYYY"));
  };

  const handleClickUpdate = () => {
    // console.log("updatedStartDate", startDate);
    // console.log("updatedEndDate", endDate);
    // setProgressBar(true);
    var candidate = props.candidate;
    candidate.start_date = startDate;
    candidate.actual_end_date = endDate;
    candidate.temp_start_date = startDate;
    candidate.temp_end_date = endDate;
    candidate.job_title = jobTitle;
    // console.log(startDate, endDate);
    if (startDate !== endDate) {
      let data = {
        user_id: props.userID,
        candidate: candidate,
      };
      // console.log(data)
      sendPostRequest(Urls.updateCandidateDate, data)
        .then((response) => {
          // console.log('Response ', response)
          if (!response) {
            setErrorMessage("Something went wrong. Please try again later.");
            setDisplayAlert(true);
          } else {
            setErrorMessage("Candidate profile updated successfully");
            setDisplayAlert(true);
          }
          // setProgressBar(false);
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
          // setProgressBar(false);
        });
    } else {
      setErrorMessage("Start date and End date can not be same");
      setDisplayAlert(true);
    }
  };

  useEffect(() => {
    // console.log("useEffect");
    props.candidate.start_date
      ? setStartDate(
        Moment(new Date(props.candidate.start_date)).format("MM/DD/YYYY")
      )
      : setStartDate(new Date());
    props.candidate.actual_end_date
      ? setEndDate(
        Moment(new Date(props.candidate.actual_end_date)).format("MM/DD/YYYY")
      )
      : setEndDate(new Date());
  }, []);

  return (
    <Fragment>
      <Dialog open={props.open}>
        <SystemAlert
          open={displayAlert}
          handleAlert={handleAlert}
          message={errorMessage}
        />
        <DialogTitle>
          <Typography variant="h6" className={style.dialogTitle}>
            Candidate Details
          </Typography>
          <IconButton className={style.closeButton} onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>Name : </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                {props.candidate.candidate_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Client :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                {props.candidate.client_details}
              </TableCell>
            </TableRow>
            {/* <TableRow ><TableCell className={style.dialogContentLeft}>Recruiter (ID) : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.candidate.recruiter_id !== "N/A" ? color.blue : color.grey) }}> {props.candidate.recruiter_id}</TableCell>
                    </TableRow> */}
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Recruiter :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                {props.candidate.recruiter}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Tax Terms :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                {props.candidate.tax_terms}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                PLC Code :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                {props.candidate.plc_code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Pass Through :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {props.candidate.isPassThrough ? "Yes" : "No"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Job Title :{" "}
              </TableCell>
              <TableCell className={style.dialogContentRight}>
                {" "}
                <TextField value={jobTitle} onChange={handleSetJobTitle} />
              </TableCell>
            </TableRow>
            {/* <TableRow ><TableCell className={style.dialogContentLeft}>Primary Skills : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.candidate.primary_skills ? color.blue : color.grey) }}> {props.candidate.primary_skills ? props.candidate.skills : "N/A"}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>Domain : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.candidate.candidate_domain ? color.blue : color.grey) }}> {props.candidate.domain ? props.candidate.domain : "N/A"}</TableCell>
                    </TableRow> */}
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                Start Date :{" "}
              </TableCell>
              <TableCell
                className={style.dialogContentRight}
                style={{
                  color: props.candidate.start_date ? color.blue : color.grey,
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="MM/DD/YYYY"
                    value={startDate}
                    maxDate={endDate ? endDate : new Date("12/31/4000")}
                    format="MM/DD/YYYY"
                    onChange={(event) => handleStartDate(event)}
                  />
                </MuiPickersUtilsProvider>
                {/* {console.log(startDate)} */}
                {/* {console.log(startDate, props.candidate.start_date)} */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={style.dialogContentLeft}>
                End Date :{" "}
              </TableCell>
              <TableCell
                className={style.dialogContentRight}
                style={{
                  color: props.candidate.actual_end_date ? color.blue : color.grey,
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="MM/DD/YYYY"
                    value={endDate}
                    minDate={startDate ? startDate : new Date("01/01/2000")}
                    format="MM/DD/YYYY"
                    onChange={(event) => handleEndDate(event)}
                  />
                </MuiPickersUtilsProvider>
                {/* {console.log(endDate, props.candidate.end_date)} */}
              </TableCell>
            </TableRow>
            {/* <TableRow> */}
            <Grid item className={style.updateButtonGrid}>
              <Button
                variant="contained"
                className={style.updateButton}
                onClick={(event) => handleClickUpdate()}
              >
                Update
              </Button>
            </Grid>
            {/* </TableRow> */}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={style.blueButton}
            style={
              props.candidate.candidate_status === "Confirmed"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) => handleMarkAsActive(event, props.candidate)}
          >
            Mark as Active
          </Button>
          <Button
            variant="contained"
            className={style.orangeButton}
            style={
              props.candidate.candidate_status === "Confirmed"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) => handleMarkAsAbsconded(event, props.candidate)}
          >
            Mark as Not Joined
          </Button>
          <Button
            variant="contained"
            className={style.blueButton}
            style={
              props.candidate.candidate_status === "Benched"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) =>
              handleMarkAsBenchedtoActive(event, props.candidate)
            }
          >
            Mark as Active
          </Button>
          <Button
            variant="contained"
            className={style.orangeButton}
            style={
              props.candidate.candidate_status === "Active"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) =>
              handleMarkAsActiveToConfirmed(event, props.candidate)
            }
          >
            Mark as Confirmed
          </Button>
          <Button
            variant="contained"
            className={style.blueButton}
            style={
              props.candidate.candidate_status === "Active"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) => handleRemoveCandidate(event, props.candidate)}
          >
            Mark as Alumni
          </Button>
          <Button
            variant="contained"
            className={style.orangeButton}
            style={
              props.candidate.candidate_status === "Absconded"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) =>
              handleMarkAsAbscondedToConfirmed(event, props.candidate)
            }
          >
            Mark as Confirmed
          </Button>
          {props.candidate.isBlacklisted ? (
            <Button
              variant="contained"
              className={style.greyButton}
              style={
                props.candidate.candidate_status === "Absconded"
                  ? { display: "block" }
                  : { display: "none" }
              }
              autoFocus
              disabled
              onClick={(event) => handleClickOpen()}
            >
              Mark as BlackListed
            </Button>
          ) : (
              <Button
                variant="contained"
                className={style.blueButton}
                style={
                  props.candidate.candidate_status === "Absconded"
                    ? { display: "block" }
                    : { display: "none" }
                }
                autoFocus
                onClick={(event) => handleClickOpen()}
              >
                Mark as BlackListed
              </Button>
            )}
          <Button
            variant="contained"
            className={style.orangeButton}
            style={
              props.candidate.candidate_status === "Alumni"
                ? { display: "block" }
                : { display: "none" }
            }
            autoFocus
            onClick={(event) =>
              handleMarkAsAlumniToActive(event, props.candidate)
            }
          >
            Mark as Active
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmation} onClose={handleClose}>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to Blacklist the candidate?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={(event) =>
              handleMarkAsAbscondedToBlackListed(event, props.candidate)
            }
            className={style.confirmationBlueButton}
          >
            Yes
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            className={style.confirmationOrangeButton}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  //modal view
  dialogTitle: {
    color: color.red,
  },
  dialogContentLeft: {
    color: color.black,
    borderBottom: "none",
    padding: theme.spacing(1),
  },
  dialogContentRight: {
    color: color.blue,
    borderBottom: "none",
    padding: theme.spacing(1),
  },
  blueButton: {
    backgroundColor: color.blue,
    "&:hover": {
      backgroundColor: color.blue,
    },
    color: color.white,
    padding: theme.spacing(1),
  },
  orangeButton: {
    backgroundColor: color.orange,
    "&:hover": {
      backgroundColor: color.orange,
    },
    color: color.white,

    padding: theme.spacing(1),
  },
  confirmationBlueButton: {
    color: color.blue,
    padding: theme.spacing(1),
  },
  confirmationOrangeButton: {
    color: color.orange,
  },
  greyButton: {
    backgroundColor: color.grey,
    variant: "contained",
  },
  closeButton: {
    color: theme.palette.grey[500],
    float: "right",
    top: "-30px",
  },
  updateButton: {
    backgroundColor: color.grey,
    variant: "contained",
    color: color.red,
  },
  updateButtonGrid: {
    float: "right",
  },
}));
