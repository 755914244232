import React, { useEffect } from "react";
import {
  Grid,
  AppBar,
  Typography,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Button,
  Tooltip,
  Badge,
} from "@material-ui/core";
import {
  FormatListBulleted,
  VerifiedUser,
  AccountCircle,
  Create,
  RateReview,
  Assessment,
  PowerSettingsNew,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { color } from "../assets/color";
import CandidateListView from "../components/CandidateListView";
import ApprovalCenter from "../components/ApprovalCenter";
import ProfileUpdate from "../components/ProfileUpdate";
import Surveys from "../components/Surveys";
import SendNotification from "../components/SendNotification";
import Cookies from "js-cookie";
import Reports from "../components/Reports";
import { sendPostRequest } from "../Actions";
import Urls from "../Urls";

export default function Dashboard(props) {
  const style = useStyles();
  // const theme = useTheme();

  // const { window } = props;

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleLogoClick = () => {
  //   setPageTitle("Candidate Lists");
  //   setPageContent(<CandidateListView userID={userID} />);
  // };

  const [pageTitle, setPageTitle] = React.useState("Candidate Lists");
  const [pageContent, setPageContent] = React.useState();
  const userID = Cookies.get("userID");
  const [approvalCount, setApprovalCount] = React.useState(0);

  const getApprovalDetails = () => {
    //fetch start date approvals
    let data = {
      user_id: userID,
      type: "Start Date",
    };
    sendPostRequest(Urls.getCandidatesForApproval, data).then((response) => {
      if (response && response.status) {
        var count = response.data.length;
        //Fetch end date approvals
        data = {
          user_id: userID,
          type: "End Date",
        };
        sendPostRequest(Urls.getCandidatesForApproval, data).then(
          (response) => {
            if (response && response.status) {
              count = count + response.data.length;
              setApprovalCount(count);
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    getApprovalDetails();
    setPageTitle("Candidate Lists");
    setPageContent(<CandidateListView userID={userID} />);
  }, []);

  const handleClick = (event, title) => {
    getApprovalDetails();
    setPageTitle(title);
    var component;
    switch (title) {
      case "Candidate Lists":
        component = <CandidateListView userID={userID} />;
        break;
      case "Approval Center":
        component = (
          <ApprovalCenter
            userID={userID}
            updateBadgeCount={getApprovalDetails}
          />
        );
        break;
      case "Profile Settings":
        component = <ProfileUpdate userID={userID} />;
        break;
      case "Surveys":
        component = <Surveys userID={userID} />;
        break;
      case "Send Notification":
        component = <SendNotification userID={userID} />;
        break;
      case "Logout":
        Cookies.remove("userID", { expires: 30 });
        props.checkLogin();
        break;
      default:
        component = <Reports userID={userID} />;
    }
    setPageContent(component);
  };

  //Set up for blue drawer on left
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  const drawer = (
    <div
      style={{
        marginTop: "20%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <List className={style.list}>
        <Tooltip title="Candidate Lists" placement="right">
          <ListItem
            button
            key={"CandidateListView"}
            onClick={(event) => handleClick(event, "Candidate Lists")}
          >
            <ListItemIcon>
              <FormatListBulleted
                className={style.listItem}
                style={{
                  fontSize: pageTitle === "Candidate Lists" ? 35 : 30,
                  color:
                    pageTitle === "Candidate Lists"
                      ? color.orange
                      : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Approval Center" placement="right">
          <ListItem
            button
            key={"ApprovalCenter"}
            onClick={(event) => handleClick(event, "Approval Center")}
          >
            <ListItemIcon>
              <Badge badgeContent={approvalCount} max={999} color="secondary">
                <VerifiedUser
                  className={style.listItem}
                  style={{
                    fontSize: pageTitle === "Approval Center" ? 35 : 30,
                    color:
                      pageTitle === "Approval Center"
                        ? color.orange
                        : color.white,
                  }}
                />
              </Badge>
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Surveys" placement="right">
          <ListItem
            button
            key={"Surveys"}
            onClick={(event) => handleClick(event, "Surveys")}
          >
            <ListItemIcon>
              <Create
                className={style.listItem}
                style={{
                  fontSize: pageTitle === "Surveys" ? 35 : 30,
                  color: pageTitle === "Surveys" ? color.orange : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Send Notification" placement="right">
          <ListItem
            button
            key={"SendNotification"}
            onClick={(event) => handleClick(event, "Send Notification")}
          >
            <ListItemIcon>
              <RateReview
                className={style.listItem}
                style={{
                  fontSize: pageTitle === "Send Notification" ? 35 : 30,
                  color:
                    pageTitle === "Send Notification"
                      ? color.orange
                      : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Reports" placement="right">
          <ListItem
            button
            key={"Reports"}
            onClick={(event) => handleClick(event, "Reports")}
          >
            <ListItemIcon>
              <Assessment
                className={style.listItem}
                style={{
                  fontSize: pageTitle === "Reports" ? 35 : 30,
                  color: pageTitle === "Reports" ? color.orange : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Profile Settings" placement="right">
          <ListItem
            button
            key={"ProfileSettings"}
            onClick={(event) => handleClick(event, "Profile Settings")}
          >
            <ListItemIcon>
              <AccountCircle
                className={style.listItem}
                style={{
                  fontSize: pageTitle === "Profile Settings" ? 35 : 30,
                  color:
                    pageTitle === "Profile Settings"
                      ? color.orange
                      : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <br />
        <Tooltip title="Logout" placement="right">
          <ListItem button key={"Logout"} onClick={handleClickOpen}>
            <ListItemIcon>
              <PowerSettingsNew
                className={style.listItem}
                style={{
                  color: open ? color.orange : color.white,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography gutterBottom>Are you sure you want to logout?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={(event) => handleClick(event, "Logout")}
            className={style.blueButton}
          >
            Yes
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            className={style.orangeButton}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <div className={style.root}>
      <CssBaseline />
      {/*Red Nav Bar*/}
      <AppBar position="fixed" className={style.redContainer}>
        <Toolbar>
          <Typography variant="h6">{pageTitle}</Typography>
        </Toolbar>
      </AppBar>
      {/*Blue drawer*/}
      <Drawer
        classes={{
          paper: style.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <img
          src={require("../assets/image/VEngageWhiteLogo.png")}
          className={style.image}
          onClick={(event) => handleClick(event, "Candidate Lists")}
        ></img>
        <Typography
          style={{ color: color.white }}
          onClick={(event) => handleClick(event, "Candidate Lists")}
        >
          V Engage
        </Typography>
        {drawer}
      </Drawer>
      <Grid container className={style.grid}>
        {pageContent}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: "100vh",
    backgroundColor: "#f9f9f9",
  },
  grid: {
    display: "flex",
    alignContent: "flex-start",
    marginTop: "8%",
    marginLeft: "14%",
    marginBottom: "4%",
    width: "80%",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "14%",
      marginTop: "10%",
    },
  },
  image: {
    width: "30%",
    marginTop: "5%",
  },

  drawerPaper: {
    backgroundColor: color.blue,
    width: "12%",
    alignItems: "center",
    flexShrink: 0,
  },
  list: {
    height: "auto",
  },
  listItem: {
    color: color.white,
    alignSelf: "center",
    fontSize: 30,
    // borderWidth:1,
  },
  //Red Nav Bar
  redContainer: {
    backgroundColor: color.red,
    width: "88%",
    marginLeft: "12%",
    boxShadow: "none",
  },
  //ModalView
  closeButton: {
    color: theme.palette.grey[500],
    float: "right",
    top: "-30px",
  },
  blueButton: {
    color: color.blue,
    padding: theme.spacing(1),
  },
  orangeButton: {
    color: color.orange,
  },
}));
