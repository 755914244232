import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {TextField} from '@material-ui/core';
import SystemAlert from "./SystemAlert";
import ChangePassword from '../components/ChangePassword';
import { useEffect } from 'react';
import ProgressBar from "./ProgressBar";
import Urls from '../Urls';
import { sendPostRequest } from '../Actions';
// import { color } from "../assets/color";
// import RefreshIcon from '@material-ui/icons/Refresh';


export default function ProfileUpdate(props) {
  const style = useStyles();
  
  const [name, setName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progressBar, setProgressBar] = useState(true);

  useEffect(() => {
    const request = {
      "user_id": props.userID
    }
    sendPostRequest(Urls.getUserProfile, request).then(response => {
      if (!response) {
        setErrorMessage("Something went wrong. Please try again later.");
        setDisplayAlert(true);
      } else {
        var user = response.data
        setName(user.user_name);
        setEmail(user.email_ID);
        setTeamName(user.team_name);
        setRole(user.user_role);
      }
      setProgressBar(!progressBar);
    }).catch(err => {
      // console.log("Error:", err);
      setErrorMessage("Please check the network and try again later.");
      setDisplayAlert(true);
    })
  }, [])

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  }

  // const handleName = (event) => {
  //   setName(event.target.value);
  // }
  // const handleTeamName = (event) => {
  //   setTeamName(event.target.value);
  // }
  // const handleEmail = (event) => {
  //   setEmail(event.target.value);
  // }
  // const handleRole = (event) => {
  //   setRole(event.target.value);
  // }

  const handleSetPasswordMessage = (event) => {
    setErrorMessage("Password has been changed succesfully");
    setDisplayAlert(true);
  }

  // const handleRefreshPage = () =>{
  //   // console.log(progressBar);
  //   setProgressBar(true);
  //   const request = {
  //     "user_id": props.userID
  //   }
  //   sendPostRequest(Urls.getUserProfile, request).then(response => {
  //     if (!response) {
  //       setErrorMessage("Something went wrong. Please try again later.");
  //       setDisplayAlert(true);
  //     } else {
  //       var user = response.data
  //       setName(user.user_name);
  //       setEmail(user.email_ID);
  //       setTeamName(user.team_name);
  //       setRole(user.user_role);
  //     }
  //     // console.log(progressBar,response);
  //     setProgressBar(false);
  //   }).catch(err => {
  //     // console.log("Error:", err);
  //     setErrorMessage("Please check the network and try again later.");
  //     setDisplayAlert(true);
  //   })
  // }

  return (
    <Grid>
      {/* <Grid container className={style.refreshIconGrid}>
        <Button
          className={style.refreshIconButton}
          onClick={handleRefreshPage}
        >
          <RefreshIcon
            className={style.refreshIcon}
          >
          </RefreshIcon>
        </Button>
      </Grid> */}
      <Card variant="outlined" className={style.card}>
        <CardContent>
          {progressBar ? <ProgressBar /> :
            <Grid container >
              <Grid item xs={6}>
                <TextField className={style.textField} label="Name" variant="outlined" value={name} />
              </Grid>
              <Grid item xs={6}>
                <TextField className={style.textField} label="Team Name" variant="outlined" value={teamName} />
              </Grid>
              <Grid item xs={6}>
                <TextField className={style.textField} label="Email" variant="outlined" value={email} />
              </Grid>
              <Grid item xs={6}>
                <TextField className={style.textField} label="Role" variant="outlined" value={role} />
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={4}>
                <ChangePassword userID={props.userID} handleSetPasswordMessage={handleSetPasswordMessage} />
              </Grid>
            </Grid>
          }
        </CardContent>
        <SystemAlert open={displayAlert} handleAlert={handleAlert} message={errorMessage} />
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    width: "75%",
    marginTop: "2%",
  },
  password: {
    width: "100%",
    marginTop: "2%",
  },
  card: {
    marginTop: "10%",
    marginLeft: "5%",
  },
  // refreshIconGrid: {
  //   display: "flex",
  //   alignContent: "flex-start",
  //   marginTop: "2%",
  //   marginLeft: "95%",
  //   marginBottom: "1%",
  //   width: "4%",
  //   boxShadow: 5,

  // },
  // refreshIcon: {
  //   fontSize: "45px",
  // },
  // refreshIconButton:
  // {
  //   backgroundColor: color.white,
  // },
}));


