import React from 'react';
import { makeStyles, TableRow, TableCell, Typography, Table, Box, Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import { Button, IconButton, Dialog, DialogContent, DialogActions, DialogTitle } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import { color } from "../assets/color";

export default function SurveyModal(props) {
    const style = useStyles()

    const getAnswerOptions = (answerType, answers) => {
        switch (answerType) {
            case 'Multiple Choice': return (answers.map(answer => <FormControlLabel
                control={<Radio />}
                value={answer} label={answer}
            />
            ))
            case 'Checkbox': return (answers.map(answer => <FormControlLabel
                control={<Checkbox name={answer} />}
                value={answer} label={answer}
            />))
            case 'Text': return (<TextField></TextField>)
            case 'Rating': return (<Box component="fieldset" mb={3} borderColor="transparent">
                <Typography component="legend">Rate Us</Typography>
                <Rating
                    defaultValue={2}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
            </Box>)
            default: return (<TextField>
            </TextField>)
        }
    }

    const handleClickEdit = (event, survey) => {
        props.handleEdit(event, props.survey)
        props.handleClose()
    }

    return (
        <Dialog open={props.open} >
            <DialogTitle className={style.dialogTitle} >
                <Typography >{props.survey.name}</Typography>
                <IconButton
                    className={style.closeButton}
                    onClick={props.handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom  >
                    {props.survey.description && (<TableRow ><TableCell className={style.dialogContentLeft} >{props.survey.description} </TableCell>
                    </TableRow>)}
                    {props.survey.questions && props.survey.questions.map((questionElement, index) => (
                        <Table>
                            <TableRow>
                                <TableCell className={style.dialogContentLeft}>
                                    {index + 1}. {questionElement.question}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={style.dialogContentLeft}>
                                    {getAnswerOptions(questionElement.answer_type, questionElement.answers)}
                                </TableCell>
                            </TableRow>
                        </Table>
                    ))}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={style.blueButton}
                    autoFocus
                    variant="contained" 
                    onClick={(event) => handleClickEdit(event, props.survey)}>
                    Edit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    dialogTitle: {
        color: color.red,
        variant: "h6"
    },
    closeButton: {
        color: color.grey,
        float: "right",
        top: '-30px'
    },
    dialogContentLeft: {
        color: color.black,
        borderBottom: "none",
        fontSize: "120%"
    },
    blueButton: {
        backgroundColor: color.blue,
        "&:hover": {
            backgroundColor: color.blue,
          },
        variant: "contained",
        color: color.white,
    },
}))