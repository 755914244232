import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, Paper, Table, Button, Tooltip } from "@material-ui/core";
import { color } from '../assets/color';
import SurveyModal from "./SurveyModal";

export default function SurveyScreen(props) {
  const style = useStyles();

  const [surveyModal, setSurveyModal] = useState(false)
  const [surveyData, setSurveyData] = useState({})


  const handleClick = (event, survey) => {
    //console.log("Row clicked", survey)
    setSurveyModal(true)
    setSurveyData(survey)
  }
  const handleClose = () => {
    setSurveyModal(!surveyModal)
  }

  const handleClickEdit = (event, survey) => {
    props.editSurvey(survey)
  }


  return (
    <Fragment>
      <Table component={Paper} className={style.table} >
        {props.surveyList.map(survey => (
          <TableRow>
            <Tooltip title="Click to view" placement="center" >
            <TableCell className={style.ContentLeft} onClick={event => handleClick(event, survey)}>{survey.name}</TableCell>
            </Tooltip>
            <TableCell className={style.ContentRight}>
              <Button className={style.button} variant="contained" onClick={event => handleClickEdit(event, survey)}>Edit</Button>
            </TableCell>
          </TableRow>
        ))
        }
      </Table>
      <SurveyModal open={surveyModal} survey={surveyData} handleClose={handleClose} handleEdit={handleClickEdit} />
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
  table: {
    width: "95%",
  },
  ContentLeft: {
    color: color.blue,
  },
  ContentRight: {
    textAlign: "right",
  },
  button: {
    backgroundColor: color.grey,
    "&:hover": {
      backgroundColor: color.grey,
    },
    variant: "contained",
    color: color.red,
  },
}))
