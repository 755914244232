import React, { Fragment, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography, TextField, Container, Card, CssBaseline, AppBar ,Toolbar} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send'
import { sendPostRequest } from '../Actions';
import { color } from "../assets/color";
import SystemAlert from "./SystemAlert";
import Urls from '../Urls';
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";

const gsmCodePoints = new Set([
    0x000a, 0x000c, 0x000d, 
    0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f,
    0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f,
    0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d,
    0x004e, 0x004f,
    0x0050, 0x0051, 0x0052, 0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059,  0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 
    0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f, 
    0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e,
    0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7,
    0x00bf,
    0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
    0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df,
    0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec,
    0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
    0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9,
    0x20ac,
]);

export default function MessageScreen(props) {
    const style = useStyles();

    const [errorStatus, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [cost, setCost] = useState(0);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [countStatus, setCountStatus] = useState(false);

    const textArea = useRef();

    const handleClose = () => {
        setOpenConfirmation(false);
    };

    const handleSendButton = () =>{
        if ( message.trim() !== "") {
            if (validateGSMText()) {
                setErrorStatus(false)
                setOpenConfirmation(true);
            } else {
                // setErrorStatus(true)
                // setErrorMessage("Message contains non-GSM characters. Please re-type your message and avoid using Non-GSM characters")
            }
            
        }else {
            setErrorStatus(true)
            setErrorMessage("Please enter message")
        }
    }

    const validateGSMText = () =>{
        //logic to allow only GSM 7-bit characters
        for (const t of message.trim()) {
            const codePoint = t.codePointAt(0);
            if (codePoint && !gsmCodePoints.has(codePoint)) {

                textArea.current.focus();
                textArea.current.selectionStart =  message.indexOf(t);
                textArea.current.selectionEnd = message.indexOf(t)+1;

                setErrorStatus(true)
                setErrorMessage('Non-GSM Detected: \''+t+'\' at positon: ' +message.indexOf(t) +'. Please use GSM character instead.')
                console.log('Non-GSM Detected:', t)
                return false; 
            }
        }
        console.log('Valid Message: ', calculate())
        return true;
       
    }

    const calculate = () =>{
        // console.log(console.log('Message Length:',message.length))
        if (message.length>160) {
            setCost((Math.ceil((message.length)/153)) * 0.0075)
        } else {
            setCost(0.0075)
        }
    }

    const handleSendMessage = () => {
        // console.log("sending message...")
        setErrorStatus(false)
        let data = {"message": message.trim()};
        props.setLoading(true)
        sendPostRequest(Urls.smsBlast, data)
        .then((responseJson)=>{
            props.setLoading(false)
            handleClose()
            if (!responseJson.status) {
                props.setAlertMessage(responseJson.message)
            } else {
                //console.log(response)
                props.setAlertMessage('Process Completed, SMS are getting sent.')
                props.checkUpload()
            }
        }).catch(err => {
            handleClose()
            // console.log("Error:", err);
            props.setLoading(false)
            props.setAlertMessage("Please check the network and try again later.");
        })        
    }

    const handleMessage = (event) => {
        setMessage(event.target.value);
        setCountStatus((message.length>160) ? true : false)
    }

    return (
        <Fragment>
            <Card style={{ justifyContentItems: "center", alignItems: "center", justifyContent: "center" }}> 
                <Grid container className={style.grid} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}> Dear <b>"employee-name"</b>, </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={style.textField}
                            onChange={handleMessage}
                            multiline
                            rows={5}
                            variant="outlined"
                            label="Message"
                            value={message}
                            error={errorStatus}
                            inputRef={textArea}
                        />
                        {errorStatus?<Typography variant={'caption'} color={'error'}>{errorMessage}</Typography>:<></>}
                        {countStatus?<Typography variant={'caption'} style={{color:color.orange}}>This SMS will cost higher than usual as there are more than 160 characters</Typography>:<></>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>Do not reply to this message.</Typography>
                    </Grid>

                    <Grid Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                        <br />
                        <Button
                            onClick={handleSendButton}
                            variant="contained"
                            className={style.button}
                            startIcon={<SendIcon />}
                        >
                            Send Message</Button>
                        <br />
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            </Card>

            <Dialog open={openConfirmation} onClose={handleClose} >
                <DialogContent>
                    <Typography variant={'h6'}>Total Cost: ${cost * props.totalRecipients}</Typography>
                    <Typography variant={'body2'}>Cost per SMS: ${cost}</Typography>   
                    {cost>0.0075 ?  <Typography variant={'body2'} color={'error'} >This SMS will cost higher than usual</Typography> : <></>}
                    <br/>
                    <Typography>Are you sure you want to send this message?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} className={style.orangeButton}>
                        No
                    </Button>
                    <Button autoFocus onClick={handleSendMessage} className={style.blueButton}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
   
    text: {
        fontSize: 18,
    },
    textField: {
        width: "100%"
    },
    button: {
        margin: theme.spacing(1),
        width: "100%",
        color: color.white,
        backgroundColor: color.blue,
    },
    redContainer: {
        backgroundColor: color.red,
        width: "100%",
        boxShadow: "none",
    },
    closeButton: {
        marginTop: "6.5%"
    },
    box: {
        display: "flex",
        color: color.white,
        width: "16.5%",
        height: "10vh",
        marginLeft: "3%",
        padding: 3,
        justifyContentItems: "center",
        alignItems: "center",
        justifyContent: "center"
    },
    candidateButton: {
        display: "flex",
        color: color.white,
        width: "17%",
        height: "10vh",
        marginLeft: "3%",
        padding: 3,
        alignItems: "center",//TODO flex-start
        justifyContent: "center",//TODO flex-start    
        textTransform: "none",
    },
    grid: {
        padding: "5%",
    },
    container: {
        marginTop: "10%",
    },
    // Progress bar while clicking button
    buttonProgress: {
        color: color.red,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    blueButton: {
        color: color.blue,
        padding: theme.spacing(1),
    },
    orangeButton: {
        color: color.orange
    },
}))

