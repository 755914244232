import React,{useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import DownloadIcon from '@material-ui/icons/GetApp';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {Tooltip} from '@material-ui/core';
import { ExportToCsv } from 'export-to-csv';

export default function HamburgerMenu(props) {
  
  const [anchorEl, setAnchorEl] = useState(null);

  const {handleLogout}=props;
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload=()=>{

    var data = [
        {
          Name: 'Name 1',
          Phone_Number: 'XXXXXXXX'
        },
        {
            Name: 'Name 2',
            Phone_Number: 'XXXXXXXX'
        }
      ];
       
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: false,
          filename: 'Sms_Sender_Template',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
       
      const csvExporter = new ExportToCsv(options);
       
      csvExporter.generateCsv(data);

      handleClose();
}

  return (
    <>
    <Tooltip title='Menu' placement="left" arrow >
      <MenuIcon
        aria-controls="simple-menu" 
        aria-haspopup="true" 
        onClick={handleClick}
      >
      </MenuIcon>
      </Tooltip>

   <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownload}>
          <DownloadIcon style={{marginRight: '20px'}} />
          Download CV Template
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon style={{marginRight: '20px'}} />
          Logout
        </MenuItem>
      </Menu>
   
    </>
  );
}
