import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Badge,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CandidateTable from "./CandidateTable";
import SystemAlert from "./SystemAlert";
import { color } from "../assets/color";
import { sendPostRequest } from "../Actions";
import ProgressBar from "./ProgressBar";
import Urls from "../Urls";
import RefreshIcon from '@material-ui/icons/Refresh';

export default function CandidateListView(props) {
  const style = useStyles();

  const [selectedCandidate, setSelectedCandidate] = useState(
    "Confirmed Candidates"
  );
  const [candidateTable, setCandidateTable] = useState();
  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progressBar, setProgressBar] = useState(true);
  // const [isBenched, setIsBenched] = useState(false);
  // const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [confirmCount, setConfirmCount] = React.useState(0);
  const [abscondedCount, setAbscondedCount] = React.useState(0);
  const [activeCount, setActiveCount] = React.useState(0);
  const [benchedCount, setBenchedCount] = React.useState(0);
  const [alumniCount, setAlumniCount] = React.useState(0);

  const handleMarkActive = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Active";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data);
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Confirmed",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Confirmed Candidates"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setActiveCount(activeCount + 1);
                setConfirmCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleMarkAbsconded = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Absconded";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Confirmed",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Confirmed Candidates"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setAbscondedCount(abscondedCount + 1);
                setConfirmCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleMarkAlumni = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Alumni";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Active",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
                setProgressBar(false);
              } else {
                var candidateList = response.data;
                var filteredData;
                if (candidateList) {
                  filteredData = candidateList.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setActiveCount(candidateList ? filteredData.length : 0);
                let data = {
                  user_id: props.userID,
                  type: "Benched",
                };
                // console.log(data)
                sendPostRequest(Urls.viewCandidate, data)
                  .then((response) => {
                    // console.log('Response ', response)
                    if (!response) {
                      setErrorMessage(
                        "Something went wrong. Please try again later."
                      );
                      setDisplayAlert(true);
                    } else {
                      if (response.data) {
                        response.data.map((element) => {
                          // console.log(response.data)
                          if (!element.isBenched) {
                            // console.log(element)
                            candidateList.push(element);
                          }
                        });
                      }
                      setCandidateTable(
                        <CandidateTable
                          userID={props.userID}
                          candidateList={candidateList}
                          title="Active Candidates"
                          handleMarkActive={handleMarkActive}
                          handleMarkAbsconded={handleMarkAbsconded}
                          handleMarkAlumni={handleMarkAlumni}
                          handleAbscondedToConfirmed={
                            handleAbscondedToConfirmed
                          }
                          handleActiveToConfirmed={handleActiveToConfirmed}
                          handleAlumniToActive={handleAlumniToActive}
                          handleAbscondedToBlackListed={
                            handleAbscondedToBlackListed
                          }
                          handleBenchedtoActive={handleBenchedtoActive}
                          updateCount={updateCount}
                        />
                      );

                      setAlumniCount(alumniCount + 1);
                      // setActiveCount(candidateList);
                    }
                    setProgressBar(false);
                  })
                  .catch((err) => {
                    // console.log("Error:", err);
                    setErrorMessage(
                      "Please check the network and try again later."
                    );
                    setDisplayAlert(true);
                    setProgressBar(false);
                  });

                // setCandidateTable(<CandidateTable userID={props.userID} candidateList={response.data} title="Active Candidates" handleMarkActive={handleMarkActive} handleMarkAbsconded={handleMarkAbsconded} handleMarkAlumni={handleMarkAlumni} handleAbscondedToConfirmed={handleAbscondedToConfirmed} handleActiveToConfirmed={handleActiveToConfirmed} handleAlumniToActive={handleAlumniToActive} handleAbscondedToBlackListed={handleAbscondedToBlackListed} handleBenchedtoActive={handleBenchedtoActive}/>);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleAbscondedToConfirmed = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Confirmed";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Absconded",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Absconded Candidates"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setConfirmCount(confirmCount + 1);
                setAbscondedCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleAbscondedToBlackListed = (event, candidate) => {
    setProgressBar(true);
    candidate.isBlacklisted = true;
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Absconded",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              console.log("Response ", response);
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Absconded Candidates"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setAbscondedCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleActiveToConfirmed = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Confirmed";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Active",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
                setProgressBar(false);
              } else {
                var candidateList = response.data;
                var filteredData;
                if (candidateList) {
                  filteredData = candidateList.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setActiveCount(candidateList ? filteredData.length : 0);
                let data = {
                  user_id: props.userID,
                  type: "Benched",
                };
                // console.log(data)
                sendPostRequest(Urls.viewCandidate, data)
                  .then((response) => {
                    // console.log('Response ', response)
                    if (!response) {
                      setErrorMessage(
                        "Something went wrong. Please try again later."
                      );
                      setDisplayAlert(true);
                    } else {
                      if (response.data) {
                        response.data.map((element) => {
                          // console.log(response.data)
                          if (!element.isBenched) {
                            // console.log(element)
                            candidateList.push(element);
                          }
                        });
                      }
                      setCandidateTable(
                        <CandidateTable
                          userID={props.userID}
                          candidateList={candidateList}
                          title="Active Candidates"
                          handleMarkActive={handleMarkActive}
                          handleMarkAbsconded={handleMarkAbsconded}
                          handleMarkAlumni={handleMarkAlumni}
                          handleAbscondedToConfirmed={
                            handleAbscondedToConfirmed
                          }
                          handleActiveToConfirmed={handleActiveToConfirmed}
                          handleAlumniToActive={handleAlumniToActive}
                          handleAbscondedToBlackListed={
                            handleAbscondedToBlackListed
                          }
                          handleBenchedtoActive={handleBenchedtoActive}
                          updateCount={updateCount}
                        />
                      );
                      // console.log(confirmCount)
                      setConfirmCount(confirmCount + 1);
                      setProgressBar(false);
                    }
                  })
                  .catch((err) => {
                    // console.log("Error:", err);
                    setErrorMessage(
                      "Please check the network and try again later."
                    );
                    setDisplayAlert(true);
                    setProgressBar(false);
                  });
                // setCandidateTable(
                //   <CandidateTable
                //     userID={props.userID}
                //     candidateList={response.data}
                //     title="Active Candidates"
                //     handleMarkActive={handleMarkActive}
                //     handleMarkAbsconded={handleMarkAbsconded}
                //     handleMarkAlumni={handleMarkAlumni}
                //     handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                //     handleActiveToConfirmed={handleActiveToConfirmed}
                //     handleAlumniToActive={handleAlumniToActive}
                //     handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                //     handleBenchedtoActive={handleBenchedtoActive}
                //   />
                // );
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleAlumniToActive = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Active";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Alumni",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Alumni"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setActiveCount(activeCount + 1);
                setAlumniCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleBenchedtoActive = (event, candidate) => {
    setProgressBar(true);
    candidate.candidate_status = "Active";
    let data = {
      user_id: props.userID,
      candidate: candidate,
    };
    //console.log(data)
    sendPostRequest(Urls.updateCandidateStatus, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        } else {
          let data = {
            user_id: props.userID,
            type: "Benched",
          };
          //console.log(data)
          sendPostRequest(Urls.viewCandidate, data)
            .then((response) => {
              //console.log('Response ', response)
              if (!response) {
                setErrorMessage(
                  "Something went wrong. Please try again later."
                );
                setDisplayAlert(true);
              } else {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title="Benched"
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
                var filteredData;
                if (response.data) {
                  filteredData = response.data.filter((plcFilteredData) =>
                    (plcFilteredData.plc_code.includes('PLC'))
                  )
                }
                setActiveCount(activeCount + 1);
                setBenchedCount(response.data ? filteredData.length : 0);
                setErrorMessage("Candidate moved successfully");
                setDisplayAlert(true);
              }
              setProgressBar(false);
            })
            .catch((err) => {
              //console.log("Error:", err);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
              setProgressBar(false);
            });
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const updateCount = (title, count) => {
    console.log(title, count);
    switch (title) {
      case "Confirmed Candidates":
        setConfirmCount(count);
        break;
      case "Absconded Candidates":
        setAbscondedCount(count);
        break;
      case "Active Candidates":
        setActiveCount(count);
        break;
      case "Benched Candidates":
        setBenchedCount(count);
        break;
      case "Alumni":
        setAlumniCount(count);
        break;
      default:
        setConfirmCount(count);
    }
  };

  useEffect(() => {
    var status = ["Confirmed", "Absconded", "Active", "Benched", "Alumni"];
    status.map((element) => {
      let data = {
        user_id: props.userID,
        type: element,
      };
      //console.log(data)
      sendPostRequest(Urls.viewCandidate, data)
        .then((response) => {
          // console.log('Response ', response)
          if (!response) {
            setErrorMessage("Something went wrong. Please try again later.");
            setDisplayAlert(true);
          } else {
            var candidateList = response.data;
            if (element === "Active") {
              var filteredData;
              if (candidateList) {
                 filteredData = candidateList.filter((plcFilteredData) =>
                  (plcFilteredData.plc_code.includes('PLC'))
                )
              }
              setActiveCount(candidateList ? filteredData.length : 0);
              let data = {
                user_id: props.userID,
                type: "Benched",
              };
              // console.log(data)
              sendPostRequest(Urls.viewCandidate, data)
                .then((response) => {
                  // console.log('Response ', response)
                  if (!response) {
                    setErrorMessage(
                      "Something went wrong. Please try again later."
                    );
                    setDisplayAlert(true);
                  } else {
                    if (response.data) {
                      response.data.map((element) => {
                        // console.log(response.data)
                        if (!element.isBenched) {
                          // console.log(element)
                          candidateList.push(element);
                        }
                      });
                    }
                    setProgressBar(false);
                  }
                })
                .catch((err) => {
                  // console.log("Error:", err);
                  setErrorMessage(
                    "Please check the network and try again later."
                  );
                  setDisplayAlert(true);
                  setProgressBar(false);
                });
            } else {
              if (element === "Confirmed") {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title={element + " Candidates"}
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
              }
              var filteredData;
              if (response.data) {
                filteredData = response.data.filter((plcFilteredData) =>
                  (plcFilteredData.plc_code.includes('PLC'))
                )
              }
              var count = response.data ? filteredData.length : 0;
              switch (element) {
                case "Confirmed":
                  setConfirmCount(count);
                  break;
                case "Absconded":
                  setAbscondedCount(count);
                  break;
                case "Benched":
                  setBenchedCount(count);
                  break;
                case "Alumni":
                  setAlumniCount(count);
                  break;
                default:
                  setConfirmCount(count);
              }
              setProgressBar(false);
            }
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        });
    });
  }, []);

  const boxClick = (event, type) => {
    setProgressBar(true);
    setSelectedCandidate(type);

    var dataType;

    switch (type) {
      case "Confirmed Candidates":
        dataType = "Confirmed"; //candidateList = confirmed_rows
        break;
      case "Absconded Candidates":
        dataType = "Absconded"; //candidateList = absconded_rows
        break;
      case "Active Candidates":
        dataType = "Active"; //candidateList = active_rows
        break;
      case "Benched Candidates":
        dataType = "Benched"; //candidateList = benched_rows
        break;
      case "Alumni":
        dataType = "Alumni"; //candidateList = alumni_rows
        break;
      default:
        dataType = "Confirmed"; //candidateList = confirmed_rows
    }

    let data = {
      user_id: props.userID,
      type: dataType,
    };
    // console.log(data)
    sendPostRequest(Urls.viewCandidate, data)
      .then((response) => {
        // console.log('Response ', response)
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
        } else {
          var candidateList = response.data;
          if (dataType === "Active") {
            var filteredData;
            if (candidateList) {
              filteredData = candidateList.filter((plcFilteredData) =>
                (plcFilteredData.plc_code.includes('PLC'))
              )
            }
            setActiveCount(candidateList ? filteredData.length : 0);
            let data = {
              user_id: props.userID,
              type: "Benched",
            };
            // console.log(data)
            sendPostRequest(Urls.viewCandidate, data)
              .then((response) => {
                // console.log('Response ', response)
                if (!response) {
                  setErrorMessage(
                    "Something went wrong. Please try again later."
                  );
                  setDisplayAlert(true);
                } else {
                  if (response.data) {
                    response.data.map((element) => {
                      // console.log(response.data)
                      if (!element.isBenched) {
                        // console.log(element)
                        candidateList.push(element);
                      }
                    });
                  }
                  setCandidateTable(
                    <CandidateTable
                      userID={props.userID}
                      candidateList={candidateList}
                      title={type}
                      handleMarkActive={handleMarkActive}
                      handleMarkAbsconded={handleMarkAbsconded}
                      handleMarkAlumni={handleMarkAlumni}
                      handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                      handleActiveToConfirmed={handleActiveToConfirmed}
                      handleAlumniToActive={handleAlumniToActive}
                      handleAbscondedToBlackListed={
                        handleAbscondedToBlackListed
                      }
                      handleBenchedtoActive={handleBenchedtoActive}
                      updateCount={updateCount}
                    />
                  );
                  setProgressBar(false);
                }
              })
              .catch((err) => {
                console.log("Error:", err);
                setErrorMessage(
                  "Please check the network and try again later."
                );
                setDisplayAlert(true);
                setProgressBar(false);
              });
          } else {
            setCandidateTable(
              <CandidateTable
                userID={props.userID}
                candidateList={response.data}
                title={type}
                handleMarkActive={handleMarkActive}
                handleMarkAbsconded={handleMarkAbsconded}
                handleMarkAlumni={handleMarkAlumni}
                handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                handleActiveToConfirmed={handleActiveToConfirmed}
                handleAlumniToActive={handleAlumniToActive}
                handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                handleBenchedtoActive={handleBenchedtoActive}
                updateCount={updateCount}
              />
            );
            var filteredData;
            if (response.data) {
              filteredData = response.data.filter((plcFilteredData) =>
                (plcFilteredData.plc_code.includes('PLC'))
              )
            }
            var count = response.data ? filteredData.length : 0;
            switch (type) {
              case "Confirmed Candidates":
                setConfirmCount(count);
                break;
              case "Absconded Candidates":
                setAbscondedCount(count);
                break;
              case "Benched Candidates":
                setBenchedCount(count);
                break;
              case "Alumni":
                setAlumniCount(count);
                break;
              default:
                setConfirmCount(count);
            }
            setProgressBar(false);
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  const handleRefreshPage = () => {
    setProgressBar(true);
    var status = ["Confirmed", "Absconded", "Active", "Benched", "Alumni"];
    status.map((element) => {
      let data = {
        user_id: props.userID,
        type: element,
      };
      //console.log(data)
      sendPostRequest(Urls.viewCandidate, data)
        .then((response) => {
          // console.log('Response ', response)
          if (!response) {
            setErrorMessage("Something went wrong. Please try again later.");
            setDisplayAlert(true);
          } else {
            var candidateList = response.data;
            if (element === "Active") {
              var filteredData;
              if (candidateList) {
                filteredData = candidateList.filter((plcFilteredData) =>
                  (plcFilteredData.plc_code.includes('PLC'))
                )
              }
              setActiveCount(candidateList ? filteredData.length : 0);
              let data = {
                user_id: props.userID,
                type: "Benched",
              };
              // console.log(data)
              sendPostRequest(Urls.viewCandidate, data)
                .then((response) => {
                  // console.log('Response ', response)
                  if (!response) {
                    setErrorMessage(
                      "Something went wrong. Please try again later."
                    );
                    setDisplayAlert(true);
                  } else {
                    if (response.data) {
                      response.data.map((element) => {
                        // console.log(response.data)
                        if (!element.isBenched) {
                          // console.log(element)
                          candidateList.push(element);
                        }
                      });
                    }
                    setProgressBar(false);
                  }
                })
                .catch((err) => {
                  // console.log("Error:", err);
                  setErrorMessage(
                    "Please check the network and try again later."
                  );
                  setDisplayAlert(true);
                  setProgressBar(false);
                });
            } else {
              if (element + " Candidates" === selectedCandidate) {
                setCandidateTable(
                  <CandidateTable
                    userID={props.userID}
                    candidateList={response.data}
                    title={element + " Candidates"}
                    handleMarkActive={handleMarkActive}
                    handleMarkAbsconded={handleMarkAbsconded}
                    handleMarkAlumni={handleMarkAlumni}
                    handleAbscondedToConfirmed={handleAbscondedToConfirmed}
                    handleActiveToConfirmed={handleActiveToConfirmed}
                    handleAlumniToActive={handleAlumniToActive}
                    handleAbscondedToBlackListed={handleAbscondedToBlackListed}
                    handleBenchedtoActive={handleBenchedtoActive}
                    updateCount={updateCount}
                  />
                );
              }
              var filteredData;
              if (response.data) {
                filteredData = response.data.filter((plcFilteredData) =>
                  (plcFilteredData.plc_code.includes('PLC'))
                )
              }
              var count = response.data ? filteredData.length : 0;
              switch (element) {
                case "Confirmed":
                  setConfirmCount(count);
                  break;
                case "Absconded":
                  setAbscondedCount(count);
                  break;
                case "Benched":
                  setBenchedCount(count);
                  break;
                case "Alumni":
                  setAlumniCount(count);
                  break;
                default:
                  setConfirmCount(count);
              }
              setProgressBar(false);
            }
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        });
    });
  }

  return (
    <Fragment>
      <Grid container className={style.refreshIconGrid}>
        <Button
          // className={style.refreshIconButton}
          onClick={handleRefreshPage}
        >
          <RefreshIcon
            className={style.refreshIcon}
          >
          </RefreshIcon>
        </Button>
      </Grid>
      <Button
        variant="contained"
        onClick={(event) => boxClick(event, "Confirmed Candidates")}
        className={style.button}
        style={{
          backgroundColor:
            selectedCandidate === "Confirmed Candidates"
              ? color.orange
              : color.blue,
        }}
      >
        <Badge
          badgeContent={confirmCount}
          className={style.badge}
          max={10000}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          showZero
        >
          Confirmed Candidates .
        </Badge>
      </Button>
      <Button
        variant="contained"
        onClick={(event) => boxClick(event, "Absconded Candidates")}
        className={style.button}
        style={{
          backgroundColor:
            selectedCandidate === "Absconded Candidates"
              ? color.orange
              : color.blue,
        }}
      >
        <Badge
          badgeContent={abscondedCount}
          className={style.badge}
          max={10000}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          showZero
        >
          Not Joined Candidates .
        </Badge>
      </Button>
      <Button
        variant="contained"
        onClick={(event) => boxClick(event, "Active Candidates")}
        className={style.button}
        style={{
          backgroundColor:
            selectedCandidate === "Active Candidates"
              ? color.orange
              : color.blue,
        }}
      >
        <Badge
          badgeContent={activeCount}
          className={style.badge}
          max={10000}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          showZero
        >
          Active Candidates ..
        </Badge>
      </Button>
      <Button
        variant="contained"
        onClick={(event) => boxClick(event, "Benched Candidates")}
        className={style.button}
        style={{
          backgroundColor:
            selectedCandidate === "Benched Candidates"
              ? color.orange
              : color.blue,
        }}
      >
        <Badge
          badgeContent={benchedCount}
          className={style.badge}
          max={10000}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          showZero
        >
          Benched Candidates .
        </Badge>
      </Button>
      <Button
        variant="contained"
        onClick={(event) => boxClick(event, "Alumni")}
        className={style.button}
        style={{
          backgroundColor:
            selectedCandidate === "Alumni" ? color.orange : color.blue,
        }}
      >
        <Badge
          badgeContent={alumniCount}
          className={style.badge}
          max={10000}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          showZero
        >
          Alumni .
        </Badge>
      </Button>
      {/* <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Absconded Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Absconded Candidates' ? color.orange : color.blue) }}
            >
                Absconded Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Active Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Active Candidates' ? color.orange : color.blue) }}
            >
                Active Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Benched Candidates')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Benched Candidates' ? color.orange : color.blue) }}
            >
                Benched Candidates
                    </Box>
            <Box
                boxShadow={7}
                onClick={event => boxClick(event, 'Alumni')}
                className={style.box}
                style={{ backgroundColor: (selectedCandidate === 'Alumni' ? color.orange : color.blue) }}
            >
                Alumni
                    </Box> */}
      {progressBar ? (
        <ProgressBar />
      ) : (
          <Grid className={style.grid}>{candidateTable}</Grid>
        )}
      <SystemAlert
        open={displayAlert}
        handleAlert={handleAlert}
        message={errorMessage}
      />
    </Fragment>
  );
}

const useStyles = makeStyles(() => ({
  grid: {
    width: "97%",
    marginTop: "3%",
  },
  // box: {
  //   display: "flex",
  //   color: color.white,
  //   width: "17%",
  //   height: "10vh",
  //   marginLeft: "3%",
  //   padding: 3,
  //   justifyContentItems: "center",
  //   alignItems: "center", //TODO flex-start
  //   justifyContent: "center", //TODO flex-start
  // },
  button: {
    display: "flex",
    color: color.white,
    width: "17%",
    height: "10vh",
    marginLeft: "3%",
    // padding: 3,
    // alignItems: "top", //TODO flex-start
    // justifyContent: "left", //TODO flex-start
    textTransform: "none",
  },
  // boxTitle:{
  //   alignItems: "top",
  //   justifyContent: "left",
  // },
  // boxCount:{
  //   alignItems: "bottom",
  //   justifyContent: "right",
  // }
  refreshIconGrid: {
    display: "flex",
    alignContent: "flex-start",
    // marginTop: "2%",
    marginLeft: "95%",
    marginBottom: "1%",
    width: "4%",
    boxShadow: 5,

  },
  refreshIcon: {
    fontSize: "45px",
  },
  // refreshIconButton:
  // {
  //   backgroundColor: color.white,
  // },
}));
