import React from 'react'
import { Button } from "@material-ui/core";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { color } from "../assets/color";
import { makeStyles } from "@material-ui/core/styles";

export const ExportCSV = ({ csvData, fileName }) => {
    const style = useStyles();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained" className={style.exportButton} onClick={(e) => exportToCSV(csvData, fileName)}>Export</Button>
    )
}

const useStyles = makeStyles((theme) => ({
    exportButton: {
        position: "absolute",
        marginLeft: "60%",
        width: "10%",
        color: color.white,
        backgroundColor: color.orange,
        "&:hover": {
            backgroundColor: color.orange,
        },
    },

}));