import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Grid,
} from "@material-ui/core";
import QuestionCard from "./QuestionCard";
import { sendPostRequest } from "../Actions";
import Urls from "../Urls";
import SystemAlert from "./SystemAlert";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ProgressBar from "./ProgressBar";

export default function CreateSurvey(props) {
  const style = useStyles();

  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [surveyID, setSurveyID] = useState(props.survey._id);
  const [surveyName, setSurveyName] = useState(props.survey.name);
  const [description, setDescription] = useState(props.survey.description);
  const [surveyType, setSurveyType] = useState(props.survey.survey_type);
  const [releaseDate, setReleaseDate] = useState(props.survey.release_date);
  const [expiryDate, setExpiryDate] = useState(props.survey.expiry_date);
  const [validityPeriod, setValidityPeriod] = useState(
    props.survey.validity_period
  );
  const [releasePeriod, setReleasePeriod] = useState(
    props.survey.release_period
  );
  const [notificationPeriod, setNotificationPeriod] = useState(
    props.survey.notification_period
  );
  const [answers, setAnswers] = useState([]);
  const [questionCardIndex, setQuestionCardIndex] = useState(
    props.questionCardList.length - 1
  );
  const [questionCardList, setQuestionCardList] = useState(
    props.questionCardList
  );
  const [surveyTypeList, setSurveyTypeList] = useState([]);
  const [progressBar, setProgressBar] = useState(false);

  useEffect(() => {
    let data = {
      user_id: props.userID,
    };
    let tempSurveyTypeList = [];

    if (
      !props.surveyList.find((f) => f.survey_type === "Pre Start Date Survey")
    ) {
      tempSurveyTypeList.push({
        name: "Pre Start Date Survey",
        value: "Pre Start Date Survey",
      });
    }

    if (!props.surveyList.find((f) => f.survey_type === "Start Date Survey")) {
      tempSurveyTypeList.push({
        name: "Joining Day Survey",
        value: "Start Date Survey",
      });
    }
    if (!props.surveyList.find((f) => f.survey_type === "Monthly Survey")) {
      tempSurveyTypeList.push({
        name: "Monthly Survey (Recurring)",
        value: "Monthly Survey",
      });
    }
    if (
      !props.surveyList.find(
        (f) => f.survey_type === "End Date Approaching Survey"
      )
    ) {
      tempSurveyTypeList.push({
        name: "End Day Approaching Survey",
        value: "End Date Approaching Survey",
      });
    }
    if (!props.surveyList.find((f) => f.survey_type === "End Date Survey")) {
      tempSurveyTypeList.push({
        name: "End Day Survey",
        value: "End Date Survey",
      });
    }
    tempSurveyTypeList.push({ name: "New Survey", value: "New Survey" });
    setSurveyTypeList(tempSurveyTypeList);

    sendPostRequest(Urls.getAnswers, data)
      .then((response) => {
        if (!response) {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
        } else {
          var answers = response.data;
          response.data.map((answer) => {
            if (
              answer.type === "Multiple Choice" ||
              answer.type === "Checkbox"
            ) {
              var answersList = "";
              answer.options.forEach((element) => {
                answersList += element + ", ";
              });
              answersList = answersList.substring(0, answersList.length - 2);
              ////console.log("Answers separated by comma:", answersList)
              answer.answersList = answersList;
            }
          });
          ////console.log(answers)
          setAnswers(answers);
        }
      })
      .catch((err) => {
        //console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
      });
  }, []);

  const clearQuestionCard = (elementIndex) => {
    var list = questionCardList.filter((f) => f.key !== elementIndex);
    setQuestionCardList(list);
  };

  const updateQuestion = (value, elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        element.question = value;
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const updateAnswerType = (value, elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        element.answer_type = value;
        if (value === "Text" || value === "Rating") {
          var answerOption = answers.filter((answer) => answer.type === value);
          //console.log("Answer option", answerOption)
          element.answer_id = answerOption[0]._id;
          //console.log(element.question)
          //console.log(element.answer_id)
        }
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const updateAnswer = (value, elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        element.answer = value;
        // console.log(element.question);
        // console.log(element.answer_type);
        // console.log(element.answer);
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const updateAnswerID = (value, elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        element.answer_id = value;
        //console.log(element.question)
        //console.log(element.answer_id)
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const handleChangeCustomRadioButton = (elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        if (element.customAnswerAdded) {
          element.answer = "";
        } else {
          element.answer_id = "";
        }
        element.addCustomRadioButton = !element.addCustomRadioButton;
        element.customAnswerAdded = !element.customAnswerAdded;
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const handleChangeCustomCheckbox = (elementIndex) => {
    const updatedQuestionCardList = [];
    questionCardList.forEach((element) => {
      if (element.key === elementIndex) {
        if (element.customAnswerAdded) {
          element.answer = "";
        } else {
          element.answer_id = "";
        }
        element.addCustomCheckbox = !element.addCustomCheckbox;
        element.customAnswerAdded = !element.customAnswerAdded;
      }
      updatedQuestionCardList.push(element);
    });
    setQuestionCardList(updatedQuestionCardList);
  };

  const handleAddQuestion = (event) => {
    var list = questionCardList;
    var index = questionCardIndex + 1;
    //console.log("Index", questionCardIndex)
    list.push({
      key: index,
      question: "",
      answer_id: "",
      answer_type: "",
      answer: "",
      addCustomRadioButton: true,
      addCustomCheckbox: true,
      customAnswerAdded: false,
    });
    setQuestionCardIndex(index);
    setQuestionCardList(list);
  };

  const validateSurvey = (isValid) => {
    if (surveyName === undefined || surveyName === "") isValid(false);
    if (surveyType === undefined || surveyType === "") isValid(false);
    if (
      (surveyType === "Start Date Survey" ||
        surveyType === "End Date Survey") &&
      !validityPeriod
    )
      isValid(false);
    if (
      surveyType === "End Date Approaching Survey" &&
      (!releasePeriod || !notificationPeriod)
    )
      isValid(false);
    if (
      (surveyType === "Monthly Survey" || surveyType === "New Survey") &&
      (releaseDate === undefined || expiryDate === undefined)
    )
      isValid(false);
    if (questionCardList.length === 0) isValid(false);
    // Old working code
    // questionCardList.forEach((questionCard) => {
    //   if (questionCard.question === undefined || questionCard.question === "") {
    //     isValid(false);
    //     return false;
    //   }
    //   if (
    //     questionCard.answer_id === undefined ||
    //     questionCard.answer_id === ""
    //   ) {
    //     isValid(false);
    //     return false;
    //   }
    // });
    var valid = true;
    const updatedQuestionCardList = [];
    var promise = new Promise((resolve, rej) => {
      questionCardList.forEach((questionCard) => {
        if (
          questionCard.question === undefined ||
          questionCard.question === ""
        ) {
          valid = false;
          resolve();
        }
        if (
          questionCard.answer_id === undefined ||
          questionCard.answer_id === ""
        ) {
          if (
            questionCard.answer === undefined ||
            questionCard.answer.trim() === ""
          ) {
            valid = false;
            resolve();
          } else {
            var options = questionCard.answer.split(",");
            var trimmedOptions = [];
            options.map((option) => {
              trimmedOptions.push(option.trim());
            });
            //console.log("Answer Options", trimmedOptions)
            let data = {
              user_id: props.userID,
              answer: {
                type: questionCard.answer_type,
                options: trimmedOptions,
                added_by: props.userID,
                added_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              },
            };
            sendPostRequest(Urls.addAnswer, data)
              .then((response) => {
                if (!response) {
                  setErrorMessage(
                    "Failed to add custom answer, please try again"
                  );
                  setDisplayAlert(true);
                  valid = false;
                } else {
                  // console.log(response.data);
                  questionCard.answer_id = response.data;
                }
                updatedQuestionCardList.push(questionCard);
                if (
                  updatedQuestionCardList.length === questionCardList.length
                ) {
                  resolve();
                }
              })
              .catch((err) => {
                setErrorMessage(
                  "Please check the network and try again later."
                );
                setDisplayAlert(true);
                valid = false;
                resolve();
              });
          }
        } else {
          updatedQuestionCardList.push(questionCard);
          if (updatedQuestionCardList.length === questionCardList.length) {
            resolve();
          }
        }
        if (updatedQuestionCardList.length === questionCardList.length) {
          resolve();
        }
      });
    });
    promise
      .then(() => {
        // console.log(updatedQuestionCardList);
        if (valid) setQuestionCardList(updatedQuestionCardList);
        isValid(valid);
      })
      .catch((err) => {
        console.log(err);
        isValid(false);
      });
  };

  const handleSaveSurvey = async () => {
    setProgressBar(true);
    //console.log("Save Survey")
    //console.log(questionCardList)
    validateSurvey((validity) => {
      if (validity) {
        var questions = [];
        var questionObject;
        questionCardList.forEach((questionCard) => {
          questionObject = {};
          questionObject.question = questionCard.question;
          questionObject.answer_id = questionCard.answer_id;
          questions.push(questionObject);
        });
        if (surveyID) {
          //console.log("Edit survey", surveyID)
          let data = {
            user_id: props.userID,
            survey: {
              _id: surveyID,
              name: surveyName,
              survey_type: surveyType,
              description: description,
              validity_period: validityPeriod,
              release_period: releasePeriod,
              notification_period: notificationPeriod,
              release_date: releaseDate,
              expiry_date: expiryDate,
              questions: questions,
            },
          };
          // //console.log(data)
          sendPostRequest(Urls.editSurvey, data)
            .then((response) => {
              if (!response) {
                setErrorMessage("Failed to edit survey, please try again");
                setDisplayAlert(true);
              } else {
                //console.log(response)
                setErrorMessage("Survey updated succesfully");
                setDisplayAlert(true);
                props.switchScreen();
              }
              setProgressBar(false);
            })
            .catch((err) => {
              setProgressBar(false);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
            });
        } else {
          //console.log("Create Survey", questions)
          let data = {
            user_id: props.userID,
            survey: {
              name: surveyName,
              survey_type: surveyType,
              description: description,
              validity_period: validityPeriod,
              release_period: releasePeriod,
              notification_period: notificationPeriod,
              release_date: releaseDate,
              expiry_date: expiryDate,
              questions: questions,
            },
          };
          sendPostRequest(Urls.createSurvey, data)
            .then((response) => {
              if (!response) {
                setErrorMessage("Failed to save survey, please try again");
                setDisplayAlert(true);
              } else {
                //console.log(response)
                setErrorMessage("Survey saved succesfully");
                setDisplayAlert(true);
                props.switchScreen();
              }
              setProgressBar(false);
            })
            .catch((err) => {
              setProgressBar(false);
              setErrorMessage("Please check the network and try again later.");
              setDisplayAlert(true);
            });
        }
      } else {
        setProgressBar(false);
        setErrorMessage(
          "One of the required fields in empty, please fill them!"
        );
        setDisplayAlert(true);
      }
    });
  };

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  // const disablePrevDates = (startDate) => {
  //   const startSeconds = Date.parse(startDate);
  //   return (date) => {
  //     return Date.parse(date) < startSeconds;
  //   };
  // };

  return (
    <Fragment>
      {progressBar ? (
        <ProgressBar />
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Survey Name"
              required
              placeholder="Enter the Survey Name for the notification"
              value={surveyName}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setSurveyName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Description"
              placeholder="Enter a short survey description (optional)"
              value={description}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={style.surveyType}>
              <InputLabel>Select a Survey Type</InputLabel>
              <Select
                value={surveyType}
                onChange={(event) => setSurveyType(event.target.value)}
              >
                {surveyTypeList.map((element) => (
                  <MenuItem value={element.value}>{element.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "Monthly Survey" || surveyType === "New Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    minDate={new Date()}
                    className={style.date}
                    label="Release Date"
                    value={releaseDate}
                    format="MM/DD/YYYY"
                    onChange={(event) => setReleaseDate(event._d)}
                  />
                </MuiPickersUtilsProvider>
                <br />
                <label>
                  Please select a particular day of the month for the survey to
                  be released
                </label>
                {/* <InputLabel className={style.dateLabel}>Release Date</InputLabel>
                        <TextField
                            className={style.date}
                            defaultValue={moment(releaseDate).format("YYYY-MM-DD")}
                            type="date"
                            onChange={event => setReleaseDate(event.target.value)}
                        /> */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "Monthly Survey" || surveyType === "New Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    // shouldDisableDate={disablePrevDates(new Date())}
                    minDate={releaseDate}
                    className={style.date}
                    label="Expiry Date"
                    value={expiryDate}
                    format="MM/DD/YYYY"
                    onChange={(event) => setExpiryDate(event._d)}
                  />
                </MuiPickersUtilsProvider>
                <br></br>
                <label>
                  Please select a particular day of the month for the survey to
                  become inactive
                </label>
                {/* <InputLabel className={style.dateLabel}>Expiry Date</InputLabel>
                        <TextField
                            className={style.date}
                            defaultValue={moment(expiryDate).format("YYYY-MM-DD")}
                            type="date"
                            onChange={event => setExpiryDate(event.target.value)}
                        /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "Start Date Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <TextField
                  label="Validity Period"
                  required
                  type="number"
                  placeholder="Enter number of days after the start date that the survey needs to be active"
                  value={validityPeriod}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    //type: Number
                  }}
                  onChange={(event) => setValidityPeriod(event.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "End Date Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <TextField
                  label="Validity Period"
                  required
                  type="number"
                  placeholder="Enter number of days after the end date that the survey needs to be active"
                  value={validityPeriod}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    //type: Number
                  }}
                  onChange={(event) => setValidityPeriod(event.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} style={(surveyType === "Monthly Survey" || surveyType === "New Survey") ? { display: 'block' } : { display: 'none' }}>
                        <InputLabel className={style.dateLabel}>Release Date</InputLabel>
                        <TextField
                            className={style.date}
                            defaultValue={moment(releaseDate).format("YYYY-MM-DD")}
                            type="date"
                            onChange={event => setReleaseDate(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={(surveyType === "Monthly Survey" || surveyType === "New Survey") ? { display: 'block' } : { display: 'none' }}>
                        <InputLabel className={style.dateLabel}>Expiry Date</InputLabel>
                        <TextField
                            className={style.date}
                            defaultValue={moment(expiryDate).format("YYYY-MM-DD")}
                            type="date"
                            onChange={event => setExpiryDate(event.target.value)}
                        />
                    </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "End Date Approaching Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <TextField
                  label="Release Period"
                  required
                  placeholder="Enter number of days prior to the end date that the survey needs to be active"
                  value={releasePeriod}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => setReleasePeriod(event.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={
                  surveyType === "End Date Approaching Survey"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <TextField
                  label="Notification Cycle"
                  required
                  placeholder="Enter number of days after which the survey notification should be sent"
                  value={notificationPeriod}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) =>
                    setNotificationPeriod(event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={style.gridCard}>
            {/* {//console.log("QuestionCardList", questionCardList)} */}
            <QuestionCard
              updateQuestion={(event, key) => updateQuestion(event, key)}
              updateAnswerTypeParentElement={(event, key) =>
                updateAnswerType(event, key)
              }
              updateAnswerParentElement={(event, key) =>
                updateAnswer(event, key)
              }
              updateAnswerID={(value, key) => updateAnswerID(value, key)}
              addCustomOption={(key) => handleChangeCustomRadioButton(key)}
              addCustomCheckbox={(key) => handleChangeCustomCheckbox(key)}
              clearQuestionCard={(event, key) => clearQuestionCard(event, key)}
              answerOptions={answers}
              data={questionCardList}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={style.button}
              onClick={handleAddQuestion}
            >
              ADD A QUESTION
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={style.button}
              onClick={handleSaveSurvey}
            >
              Save
            </Button>
            {/* <Button
                    variant="contained"
                    color="primary"
                    className={style.button}
                    endIcon={<SendIcon></SendIcon>}>
                    Save and Send
                </Button> */}
          </Grid>
          <SystemAlert
            open={displayAlert}
            handleAlert={handleAlert}
            message={errorMessage}
          />
        </Grid>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  gridCard: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
  surveyType: {
    //margin: theme.spacing(1),
    minWidth: 200,
    marginTop: "2%",
  },
  date: {
    minWidth: 200,
    marginTop: "2%",
    //marginLeft: "2%",
  },
  dateLabel: {
    fontSize: 10,
    marginTop: "10%",
    //marginLeft: "8%",
  },
  radioButtonCustomControlAlignment: {
    display: "inline-flex",
  },
  radioButtonAligntment: {
    paddingLeft: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
}));
