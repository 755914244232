import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, TextField, AppBar, Toolbar, Button, Grid } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell, TableContainer } from "@material-ui/core";
import { sendPostRequest } from "../Actions";
import SystemAlert from "./SystemAlert";
import { color } from '../assets/color';
import Urls from '../Urls';
import ProgressBar from './ProgressBar';

export default function ApprovalSetting(props) {
    const style = useStyles();

    const [displayAlert, setDisplayAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [rows, setRows] = useState([]);
    const [progressBar, setProgressBar] = useState(true);
    //setting-table
    function createData(name, value, key, note) {
        return { name, value, key, note };
    }

    const handleApprovalSettingsValue = (event, key) => {
        var updatedRows = []
        rows.map(m => {
            if (m.key === key) {
                m.value = event.target.value
            }
            updatedRows.push(m)
        });
        setRows(updatedRows)
    }
    const saveApprovalSetting = (event, key, value, name) => {
        if (value) {
            let data = {
                "const_name": key,
                "const_value": value,
                "modified_by": props.userID
            }
            sendPostRequest(Urls.updateSettings, data).then(response => {
                if (!response) {
                    setErrorMessage("Something went wrong. Please try again later.");
                    setDisplayAlert(true);
                } else {
                    setErrorMessage(name + " updated successfully");
                    setDisplayAlert(true);
                    getApprovalSettings();
                }
                setProgressBar(false);
            }).catch(err => {
                // console.log("Error:", err);
                setErrorMessage("Please check the network and try again later.");
                setDisplayAlert(true);
                setProgressBar(false);
            })
        }
        else {
            setErrorMessage("Enter the value.");
            setDisplayAlert(true);
        }
    }

    const handleAlert = () => {
        setDisplayAlert(!displayAlert);
        setErrorMessage("");
    }

    const getApprovalSettings = () => {
        const request = {
            "user_id": props.userID
        }
        let tempData = [
            createData("Active to Benched", "", "Active_To_Benched_Period", "Number of days prior to the end date that a candidate needs to be moved to benched status"),
            createData("Benched to Alumni", "", "Benched_To_Alumni_Period", "Number of days after the end date that a candidate needs to be moved to alumni status"),
            createData("Absconded to Alumni", "", "Absconded_To_Alumni_Period", "Number of days after the start date that a candidate needs to be moved to alumni status"),
        ];
        sendPostRequest(Urls.getSettings, request).then(response => {
            if (response && response.status && response.data) {
                tempData.forEach(element => {
                    let filteredSetting = response.data.find(f => f.const_name === element.key);
                    if (filteredSetting && filteredSetting["const_value"]) {
                        element.value = filteredSetting["const_value"]
                    }
                });
                setRows(tempData);
                setProgressBar(false);
            }
        }).catch(err => {
            // console.log("Error:", err);
            setErrorMessage("Please check your network and try again later.");
            setDisplayAlert(true);
            setProgressBar(false);
        })
    }
    useEffect(() => {
        getApprovalSettings();
    }, []);

    return (
        <Grid>
            <AppBar position="static" variant="outlined" className={style.appbar}>
                <Toolbar>
                    <Typography variant="h6" className={style.title}>
                        Edit Lifecycle Days
                    </Typography>
                </Toolbar>
            </AppBar>
            {progressBar ? <ProgressBar /> :
                <TableContainer component={Paper} className={style.settingsTable}>
                    <Table className={style.table} aria-label="simple table">
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                        <div className={style.note}>{row.note}</div>
                                    </TableCell>
                                    <TableCell > <TextField
                                        className={style.spinner}
                                        type="number"
                                        value={row.value}
                                        onChange={event => handleApprovalSettingsValue(event, row.key)}
                                        variant="outlined"
                                    /></TableCell>
                                    <TableCell >
                                        <Button
                                            variant="contained"
                                            onClick={event => saveApprovalSetting(event, row.key, row.value, row.name, row.note)}
                                            className={style.button}>Save</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <SystemAlert open={displayAlert} handleAlert={handleAlert} message={errorMessage} />
        </Grid>
    );
}

const useStyles = makeStyles({
    appbar: {
        flexGrow: 1,
        color: color.red,
        backgroundColor: color.white,
        marginTop: "3%",
        width: "70%",
        marginLeft: "15%"
    },
    title: {
        flexGrow: 1,
    },
    table: {
        width: "100%",
    },
    settingsTable: {
        width: "70%",
        marginLeft: "15%"
    },
    button: {
        color: color.white,
        backgroundColor: color.orange,
        "&:hover": {
            backgroundColor: color.orange
        }
    },
    spinner: {
        width: "40%",
        height: "3%"
    },
    note: {
        fontSize: 10,
        color: color.blue
    }
});