import React, { Fragment, Component } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { color } from "../assets/color";
import CreateSurvey from "./CreateSurvey";
import SurveyScreen from "./SurveyScreen";
import { sendPostRequest } from "../Actions";
import Urls from "../Urls";
import SystemAlert from "./SystemAlert";
import ProgressBar from "./ProgressBar";
// import RefreshIcon from '@material-ui/icons/Refresh';

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: props.userID,
      content: <></>,
      isViewSurvey: true,
      surveyList: [],
      displayAlert: false,
      errorMessage: "",
      progressBar: true,
      questionCardList: [
        {
          key: 0,
          question: "",
          answer_id: "",
          answer_type: "",
          answer: "",
          addCustomRadioButton: true,
          addCustomCheckbox: true,
          customAnswerAdded: false,
        },
      ],
    };
  }

  componentDidMount() {
    let data = {
      user_id: this.state.userID,
    };
    // console.log("Call view survey", data);
    sendPostRequest(Urls.viewSurvey, data)
      .then((response) => {
        //console.log(response)
        if (!response) {
          this.setState({
            errorMessage: "Something went wrong. Please try again later.",
            displayAlert: true,
          });
        } else {
          this.setState({
            surveyList: response.data,
            content: (
              <SurveyScreen
                userID={this.state.userID}
                surveyList={response.data}
                editSurvey={this.editSurvey}
              />
            ),
          });
        }
        var proBar = this.state.progressBar;
        //console.log(proBar)
        this.setState({
          progressBar: !proBar,
        });
      })
      .catch((err) => {
        //console.log("Error:", err);
        var proBar = this.state.progressBar;
        this.setState({
          errorMessage: "Please check the network and try again later.",
          displayAlert: true,
          progressBar: !proBar,
        });
      });
  }

  handleAlert = () => {
    this.setState({
      errorMessage: "",
      displayAlert: false,
    });
  };

  handleCreate = () => {
    if (!this.state.isViewSurvey) {
      let data = {
        user_id: this.state.userID,
      };
      sendPostRequest(Urls.viewSurvey, data)
        .then((response) => {
          if (!response) {
            this.setState({
              errorMessage: "Something went wrong. Please try again later.",
              displayAlert: true,
            });
          } else {
            var isViewSurvey = this.state.isViewSurvey;
            this.setState({
              surveyList: response.data,
              isViewSurvey: !isViewSurvey,
              content: (
                <SurveyScreen
                  userID={this.state.userID}
                  surveyList={response.data}
                  editSurvey={this.editSurvey}
                />
              ),
            });
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          this.setState({
            errorMessage: "Please check the network and try again later.",
            displayAlert: true,
          });
        });
    } else {
      var questionCardList = [
        {
          key: 0,
          question: "",
          answer_id: "",
          answer_type: "",
          answer: "",
          addCustomRadioButton: true,
          addCustomCheckbox: true,
          customAnswerAdded: false,
        },
      ];
      var isViewSurvey = this.state.isViewSurvey;
      var surveyList = this.state.surveyList;
      this.setState({
        questionCardList: questionCardList,
        isViewSurvey: !isViewSurvey,
        content: (
          <CreateSurvey
            userID={this.state.userID}
            switchScreen={this.handleCreate}
            questionCardList={questionCardList}
            survey={{}}
            surveyList={surveyList}
          />
        ),
      });
    }
  };

  editSurvey = (survey) => {
    //console.log(survey)
    var questionList = [];
    var question;
    var surveyList = [
      { survey_type: "Start Date Survey" },
      { survey_type: "Monthly Survey" },
      { survey_type: "End Date Approaching Survey" },
      { survey_type: "End Date Survey" },
      { survey_type: "New Survey" },
    ];
    var count = 0;
    survey.questions.map((element) => {
      question = element;
      question.key = count;
      question.addCustomCheckbox = true;
      question.addCustomRadioButton = true;
      question.customAnswerAdded = false;
      questionList.push(question);
      count++;
    });
    var filteredList = surveyList.filter(
      (element) => element.survey_type !== survey.survey_type
    );
    //console.log("Before set state", questionList)
    this.setState({
      questionCardList: questionList,
      isViewSurvey: false,
      content: (
        <CreateSurvey
          userID={this.state.userID}
          switchScreen={this.handleCreate}
          questionCardList={questionList}
          survey={survey}
          surveyList={filteredList}
        />
      ),
      // surveyID={survey._id} surveyName={survey.name} description={survey.description}
      // surveyType={survey.survey_type}
      // releaseDate={survey.release_date} expiryDate={survey.expiry_date} />
    });
  };

  // handleRefreshPage = () => {
  //   this.setState({
  //     progressBar: true,
  //   });
  //   let data = {
  //     user_id: this.state.userID,
  //   };
  //   // console.log("Call view survey", data);
  //   sendPostRequest(Urls.viewSurvey, data)
  //     .then((response) => {
  //       //console.log(response)
  //       if (!response) {
  //         this.setState({
  //           errorMessage: "Something went wrong. Please try again later.",
  //           displayAlert: true,
  //         });
  //       } else {
  //         this.setState({
  //           surveyList: response.data,
  //           content: (
  //             <SurveyScreen
  //               userID={this.state.userID}
  //               surveyList={response.data}
  //               editSurvey={this.editSurvey}
  //             />
  //           ),
  //         });
  //       }
  //       var proBar = this.state.progressBar;
  //       //console.log(proBar)
  //       this.setState({
  //         progressBar: !proBar,
  //       });
  //     })
  //     .catch((err) => {
  //       //console.log("Error:", err);
  //       var proBar = this.state.progressBar;
  //       this.setState({
  //         errorMessage: "Please check the network and try again later.",
  //         displayAlert: true,
  //         progressBar: !proBar,
  //       });
  //     });
  // }

  render() {
    const button = {
      width: "15%",
      marginLeft: "80%",
      backgroundColor: color.orange,
      color: color.white,
      marginBottom: "5%",
      "&:hover": {
        backgroundColor: color.orange,
      },
    };
    // const refreshIconGrid = {
    //   display: "flex",
    //   alignContent: "flex-start",
    //   marginTop: "2%",
    //   marginLeft: "95%",
    //   marginBottom: "1%",
    //   width: "4%",
    //   boxShadow: 5,

    // };
    // const refreshIcon = {
    //   fontSize: "45px",
    // };
    // const refreshIconButton = {
    //   backgroundColor: color.white,
    // };


    return (
      <Fragment>
        {/* <Grid container style={refreshIconGrid}>
          <Button
            style={refreshIconButton}
            onClick={this.handleRefreshPage}
          >
            <RefreshIcon
              style={refreshIcon}
            >
            </RefreshIcon>
          </Button>
        </Grid> */}
        <SystemAlert
          open={this.state.displayAlert}
          handleAlert={this.handleAlert}
          message={this.state.errorMessage}
        />
        {this.state.progressBar ? (
          <ProgressBar />
        ) : (
            <Grid container style={{ marginLeft: "5%" }}>
              <Grid
                style={
                  this.state.isViewSurvey === true
                    ? { display: "block" }
                    : { display: "none" }
                }
                item
                xs={12}
              >
                <Button style={button} variant="contained" onClick={this.handleCreate}>
                  Create Survey
              </Button>
              </Grid>
              <Grid
                style={
                  this.state.isViewSurvey === false
                    ? { display: "block" }
                    : { display: "none" }
                }
                item
                xs={12}
              >
                <Button style={button} variant="contained" onClick={this.handleCreate}>
                  Back to Survey
              </Button>
              </Grid>
              <Grid item xs={12}>
                {this.state.content}
              </Grid>
            </Grid>
          )}
      </Fragment>
    );
  }
}

export default Surveys;

// export default function Surveys(props) {
//     const style = useStyles()

//     const [content, setContent] = useState()
//     const [isViewSurvey, setIsViewSurvey] = useState(true)
//     const [surveyList, setSurveyList] = useState([])
//     const [displayAlert, setDisplayAlert] = useState(false)
//     const [errorMessage, setErrorMessage] = useState("")
//     const [progressBar, setProgressBar] = useState(true)
//     const [questionCardList, setQuestionCardList] = useState([{
//         key: 0,
//         question: "",
//         answer_id: "",
//         answer_type: "",
//         answer: "",
//         addCustomRadioButton: true,
//         addCustomCheckbox: true
//     }]);

//     useEffect(() => {
//         let data = {
//             "user_id": props.userID,
//         }
//         sendPostRequest(Urls.viewSurvey, data).then(response => {
//             if (!response) {
//                 setErrorMessage("Something went wrong. Please try again later.")
//                 setDisplayAlert(true)
//             } else {
//                 setSurveyList(response.data)
//             }
//             setProgressBar(!progressBar)
//         }).catch(err => {
//             //console.log("Error:", err);
//             setErrorMessage("Please check the network and try again later.")
//             setDisplayAlert(true)
//         })
//     }, [])

//     const handleCreate = () => {
//         if (!isViewSurvey) {
//             let data = {
//                 "user_id": props.userID,
//             }
//             sendPostRequest(Urls.viewSurvey, data).then(response => {
//                 if (!response) {
//                     setErrorMessage("Something went wrong. Please try again later.")
//                     setDisplayAlert(true)
//                 } else {
//                     setSurveyList(response.data)
//                     setIsViewSurvey(!isViewSurvey)
//                 }
//             }).catch(err => {
//                 //console.log("Error:", err);
//                 setErrorMessage("Please check the network and try again later.")
//                 setDisplayAlert(true)
//             })
//         } else {
//             setQuestionCardList({
//                 key: 0,
//                 question: "",
//                 answer_id: "",
//                 answer_type: "",
//                 answer: "",
//                 addCustomRadioButton: true,
//                 addCustomCheckbox: true
//             })
//             setContent(<CreateSurvey userID={props.userID} switchScreen={switchToViewSurvey} questionCardList={questionCardList} />)
//             setIsViewSurvey(!isViewSurvey)
//         }
//     }

//     const switchToViewSurvey = () => {
//         //console.log("Switch to view screen")
//         setQuestionCardList({
//             key: 0,
//             question: "",
//             answer_id: "",
//             answer_type: "",
//             answer: "",
//             addCustomRadioButton: true,
//             addCustomCheckbox: true
//         })
//         let data = {
//             "user_id": props.userID,
//         }
//         sendPostRequest(Urls.viewSurvey, data).then(response => {
//             if (!response) {
//                 setErrorMessage("Something went wrong. Please try again later.")
//                 setDisplayAlert(true)
//             } else {
//                 setSurveyList(response.data)
//                 setIsViewSurvey(true)
//             }
//         }).catch(err => {
//             //console.log("Error:", err);
//             setErrorMessage("Please check the network and try again later.")
//             setDisplayAlert(true)
//         })
//     }

//     const handleAlert = () => {
//         setDisplayAlert(!displayAlert)
//         setErrorMessage("")
//     }

//     const editSurvey = (survey) => {
//         //console.log(survey.questions)
//         var questionList = []
//         var question
//         var count = 0
//         survey.questions.map(element => {
//             question = element
//             question.key = count
//             question.addCustomCheckbox = false
//             question.addCustomRadioButton = false
//             questionList.push(question)
//             count++
//         })
//         setQuestionCardList(questionList)
//         setIsViewSurvey(false)
//         //console.log("Before set content", questionList)
//         setContent(<CreateSurvey userID={props.userID} switchScreen={handleCreate} questionCardList={questionList}
//             surveyName={survey.name} description={survey.description} surveyType={survey.survey_type} />)
//     }

//     return (
//         <Fragment>
//             <SystemAlert open={displayAlert} handleAlert={handleAlert} message={errorMessage} />
//             {progressBar ? <ProgressBar /> :
//                 <Grid container className={style.root} >
//                     <Grid style={isViewSurvey === true ? { display: 'block' } : { display: 'none' }} item xs={12} >
//                         <Button className={style.button} onClick={handleCreate}>Create Survey</Button>
//                     </Grid>
//                     <Grid style={isViewSurvey === false ? { display: 'block' } : { display: 'none' }} item xs={12} >
//                         <Button className={style.button} onClick={handleCreate}>Back to Survey</Button>
//                     </Grid>
//                     <Grid style={isViewSurvey === true ? { display: 'block' } : { display: 'none' }} item xs={12}>
//                         <SurveyScreen userID={props.userID} surveyList={surveyList} editSurvey={editSurvey} />
//                     </Grid>
//                     <Grid style={isViewSurvey === false ? { display: 'block' } : { display: 'none' }} item xs={12} >
//                         {content}
//                     </Grid>
//                     {/* <Grid style={isViewSurvey === false ? { display: 'block' } : { display: 'none' }} item xs={12}>
//                     <CreateSurvey userID={props.userID} switchScreen={handleCreate} questionCardList={questionCardList} />
//                 </Grid> */}
//                 </Grid >
//             }
//         </Fragment>
//     );
// }
// const useStyles = makeStyles(() => ({
//     button: {
//         width: "15%",
//         marginLeft: "80%",
//         backgroundColor: color.blue,
//         color: color.white,
//         marginBottom: "5%",
//         "&:hover": {
//             backgroundColor: color.blue
//         }
//     },
// }));
