import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './pages/Login.jsx';
import Dashboard from './pages/Dashboard.jsx';
import MessageScreen from './components/MessageScreen.jsx';
import FileUpload from './components/FileUpload.jsx';
import SmsSender from './pages/SmsSender.jsx';
// import { color } from './assets/color.js';


function App() {
  const [content, setContent] = useState(<></>)

  useEffect(() => {
    checkLogin()
  }, [])

  const checkLogin = () => {
    var userID = Cookies.get("userID")
    var userRole = Cookies.get("userRole")

    setContent(<SmsSender/>)
    
    if (userID) {
      // console.log('UserRole: ',userRole)
      if (userRole=='sms_sender'){
        setContent(<SmsSender checkLogin={checkLogin}/>)
      }else{
        setContent(<Dashboard checkLogin={checkLogin} />)
      }
    } else {
      setContent(<Login checkLogin={checkLogin} />)
    }
  }

  return (
      <BrowserRouter >
        <Switch >
          <Route exact path='/'>
            {content}
          </Route>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
