import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  FormControl,
  FormGroup,
  Select,
  Button,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import {
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import RadioButton from "@material-ui/core/Radio";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useState } from "react";
// import { useEffect } from "react";

export default function QuestionCard(props) {
  const style = useStyles();

  const [answersType, setAnswersType] = useState([]);

  if (answersType.length === 0 && props.answerOptions.length) {
    var lookup = {};
    var result = [];
    props.answerOptions.map((item) => {
      var answer_type = item.type;
      if (!(answer_type in lookup)) {
        lookup[answer_type] = 1;
        result.push(answer_type);
      }
    });
    //console.log("Result", result)
    setAnswersType(result);
  }

  const clearQuestionCard = (event, index) => {
    props.clearQuestionCard(index);
  };

  const handleChangeQuestion = (event, index) => {
    props.updateQuestion(event.target.value, index);
  };

  const handleChangeAnswerType = (event, index) => {
    props.updateAnswerTypeParentElement(event.target.value, index);
  };

  const handleChangeAnswer = (event, index) => {
    props.updateAnswerParentElement(event.target.value, index);
  };

  const handleChangeAnswerID = (event, index) => {
    props.updateAnswerID(event.target.value, index);
  };

  const addCustomOption = (event, index) => {
    props.addCustomOption(index);
  };

  const addCustomCheckbox = (event, index) => {
    props.addCustomCheckbox(index);
  };

  return (
    <div>
      {/* {console.log("Question Card",props.data)} */}
      {props.data.map((element) => (
        <Card
          className={style.questionCard}
          key={element.key}
          variant="outlined"
        >
          <CardContent>
            <label style={{ position: "relative", top: "0", float: "right" }}>
              <ClearIcon
                onClick={(event) => clearQuestionCard(event, element.key)}
              />
            </label>
            <TextField
              label="Question"
              placeholder="Enter a question"
              value={element.question}
              fullWidth
              margin="normal"
              onChange={(event) => handleChangeQuestion(event, element.key)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl className={style.answer_type}>
              <InputLabel>Select an answer type</InputLabel>
              <Select
                value={element.answer_type}
                onChange={(event) => handleChangeAnswerType(event, element.key)}
              >
                {answersType.map((answer) => {
                  return <MenuItem value={answer}>{answer}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <div
              style={
                element.answer_type === "Multiple Choice"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <label>Note : Please select any one of the options </label>
              <br></br>
              <FormControl component="fieldset">
                <RadioGroup
                  name="answer"
                  value={element.answer_id}
                  onChange={(event) => handleChangeAnswerID(event, element.key)}
                >
                  {props.answerOptions.map((answer) => {
                    if (answer.type === "Multiple Choice") {
                      return (
                        <FormControlLabel
                          value={answer._id}
                          control={<Radio />}
                          label={answer.answersList}
                        />
                      );
                    }
                  })}
                  {
                    //check if you added an answer
                    element.customAnswerAdded && (
                      <label
                        className={style.radioButtonCustomControlAlignment}
                      >
                        <RadioButton
                          className={style.radioButtonAlignment}
                          checked={true} //{element.answer}
                          // onChange={handleChangeAnswer}
                        >
                          {" "}
                        </RadioButton>
                        <TextField
                          fullWidth
                          margin="normal"
                          onChange={(event) =>
                            handleChangeAnswer(event, element.key)
                          }
                        />
                        <ClearIcon
                          onClick={(event) =>
                            addCustomOption(event, element.key)
                          }
                          className={style.closeButton}
                        />
                        <label>
                          Note : Please add comma separated options{" "}
                        </label>
                        <br></br>
                      </label>
                    )
                  }
                  <label className={style.radioButtonCustomControlAlignment}>
                    <RadioButton
                      disabled={!element.addCustomRadioButton}
                      onChange={(event) => addCustomOption(event, element.key)}
                      className={style.radioButtonAlignment}
                    >
                      {" "}
                    </RadioButton>
                    <Button
                      disabled={!element.addCustomRadioButton}
                      onClick={(event) => addCustomOption(event, element.key)}
                      color="primary"
                    >
                      Add Custom Options
                    </Button>
                    <label htmlFor="icon-button-file">
                      <IconButton
                        disabled={!element.addCustomRadioButton}
                        onClick={(event) => addCustomOption(event, element.key)}
                        color="primary"
                        component="span"
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </label>
                  </label>
                </RadioGroup>
              </FormControl>
            </div>
            <div
              style={
                element.answer_type === "Checkbox"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <label>Note : Please select any one of the options </label>
              <br></br>
              <FormGroup component="fieldset">
                <RadioGroup
                  name="answer"
                  value={element.answer_id}
                  onChange={(event) => handleChangeAnswerID(event, element.key)}
                >
                  {props.answerOptions.map((answer) => {
                    if (answer.type === "Checkbox") {
                      return (
                        <FormControlLabel
                          value={answer._id}
                          control={<Radio />}
                          // {<Checkbox onChange={(event) => handleChangeAnswerID(event, element.key)} />}
                          label={answer.answersList}
                        />
                      );
                    }
                  })}
                </RadioGroup>
              </FormGroup>
              {element.customAnswerAdded && (
                <label className={style.checkBoxCustomControlAlignment}>
                  <Checkbox
                    className={style.checkBoxAlignment}
                    checked={true} //{element.answer}
                    // onChange={handleChangeAnswer}
                  >
                    {" "}
                  </Checkbox>
                  <TextField
                    fullWidth
                    margin="normal"
                    onChange={(event) => handleChangeAnswer(event, element.key)}
                  />
                  <ClearIcon
                    onClick={(event) => addCustomCheckbox(event, element.key)}
                    className={style.closeButton}
                  />
                  <label>Note : Please add comma separated options </label>
                  <br></br>
                </label>
              )}
              <label className={style.checkBoxCustomControlAlignment}>
                <Checkbox
                  disabled={!element.addCustomCheckbox}
                  checked={false}
                  onChange={(event) => addCustomCheckbox(event, element.key)}
                  className={style.checkBoxAlignment}
                >
                  {" "}
                </Checkbox>
                <Button
                  disabled={!element.addCustomCheckbox}
                  onClick={(event) => addCustomCheckbox(event, element.key)}
                  color="primary"
                  variant="contained" 
                >
                  Add Custom Options
                </Button>
                <label htmlFor="icon-button-file">
                  <IconButton
                    disabled={!element.addCustomCheckbox}
                    onClick={(event) => addCustomCheckbox(event, element.key)}
                    color="primary"
                    component="span"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </label>
              </label>
            </div>
            <div
              style={
                element.answer_type === "Text"
                  ? { display: "block" }
                  : { display: "none" }
              }
            ></div>
            <div
              style={
                element.answer_type === "Rating"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography component="legend">Rate Us</Typography>
                <Rating
                  defaultValue={2}
                  precision={0.5}
                  onChange={(event) => handleChangeAnswer(event, element.key)}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </Box>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  questionCard: {
    minWidth: 275,
    marginTop: "20px",
  },
  answer_type: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  radioButtonCustomControlAlignment: {
    display: "inline-flex",
  },
  radioButtonAlignment: {
    paddingLeft: 0,
  },
  checkBoxCustomControlAlignment: {
    display: "inline-flex",
  },
  checkBoxAlignment: {
    paddingLeft: 0,
  },
  closeButton: {
    marginTop: 15,
  },
  textFieldOption: {
    width: "50%",
  },
}));
