import React, { useState, Fragment } from "react";
import {
  makeStyles,
  CssBaseline,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Paper, Grid, TextField, Typography, Button } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { color } from "../assets/color";
import { validateEmail, sendPostRequest, validatePassword } from "../Actions";
import Urls from "../Urls";
import Cookies from "js-cookie";
import SystemAlert from "../components/SystemAlert";
import { Link } from "react-router-dom";

export default function Login(props) {
  const style = useStyles();

  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isComparePassword, setIsComparePassword] = useState(false);
  const [isCompareOldPassword, setIsCompareOldPassword] = useState(false);
  const [isGenerateOtpModal, setIsGenerateOtpModal] = useState(false);
  const [isVerifyOtpModal, setIsVerifyOtpModal] = useState(false);
  const [isSetPasswordModal, setIsSetPasswordModal] = useState(false);
  const [isContactUs, setIsContactUs] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCompareEmail, setIsCompareEmail] = useState(false);

  const handleLogin = () => {
    console.log('Sending login request')
    if (validateEmail(userID)) {
      let data = {
        user_ID: userID,
        user_password: password,
      };
      // console.log("Login Data: ",data);
      sendPostRequest(Urls.login, data)
        .then((response) => {
          console.log("Login Response: ",response.status)
          if (!response.status) {
            console.log('login unsuccesful')
            setErrorMessage("You've entered invalid credentials");
            setDisplayAlert(true);
            props.checkLogin();
          } else {
            console.log("Login Successful for ",userID)
            
            //less secure for testing
            // Cookies.set("userID", userID, { expires: 30});
            // Cookies.set("userRole", response.data.user_role, { expires: 30}); 

            //Production code:
            Cookies.set("userID", userID, { expires: 30, secure: true });
            Cookies.set("userRole", response.data.user_role, { expires: 30, secure: true });

            props.checkLogin();
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check your network and try again later.");
          setDisplayAlert(true);
        });
    } else {
      setErrorMessage("You've entered invalid credentials");
      setDisplayAlert(true);
    }
  };

  const handleInput = (event) => {
    setUserID(event.target.value);
  };

  const handleInputVerifyOtp = (event) => {
    setVerifyOtp(event.target.value);
  };

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  const handleCloseVerifyOtpModal = () => {
    setIsVerifyOtpModal(false);
  };

  const handleCloseSetPasswordModal = () => {
    setIsSetPasswordModal(false);
  };

  const handleForgotPassword = () => {
    setIsGenerateOtpModal(true);
  };

  const handleClose = () => {
    setUserID("");
    setIsGenerateOtpModal(false);
  };

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
    if (event.target.value !== confirmPassword) {
      setIsComparePassword(true);
    } else {
      setIsComparePassword(false);
    }
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    if (newPassword !== event.target.value) {
      setIsComparePassword(true);
    } else {
      setIsComparePassword(false);
    }
  };

  const handleGenerateOtp = () => {
    if (userID.trim() !== "") {
      const request = { user_id: userID };
      sendPostRequest(Urls.generateOtp, request)
        .then((response) => {
          //  debugger
          if (response && response.status) {
            setIsGenerateOtpModal(false);
            setIsVerifyOtpModal(true);
            // Cookies.set("userID", userID, { expires: 30 })
            // //console.log(Cookies.get("userID"))
          } else {
            //console.log('login unsuccesful')
            setErrorMessage("Enter the registered email address");
            setDisplayAlert(true);
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
        });
    } else {
      setErrorMessage("Enter the registered email address");
      setDisplayAlert(true);
    }
  };

  const handleVerifyOtpModal = () => {
    if (verifyOtp.trim() !== "") {
      const request = {
        user_id: userID,
        otp: parseInt(verifyOtp),
      };
      sendPostRequest(Urls.verifyOtp, request)
        .then((response) => {
          //  debugger
          // console.log("otpResponse: ", response)
          if (response && response.status) {
            if(response.data && verifyOtp==response.data.otp){
              setIsVerifyOtpModal(false);
              setIsSetPasswordModal(true);
            }else{
              setErrorMessage("Invalid OTP. Make sure you enter the OTP within 15 minutes from generation.");
              setDisplayAlert(true);
            }
            
            // Cookies.set("userID", userID, { expires: 30 })
            // //console.log(Cookies.get("userID"));
          } else {
            setErrorMessage("Invalid OTP");
            setDisplayAlert(true);
          }
        })
        .catch((err) => {
          // console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
        });
    } else {
      setErrorMessage("Enter the One Time Password");
      setDisplayAlert(true);
    }
  };

  const handleSetPassword = () => {
    if (isComparePassword || isCompareOldPassword) {
      setErrorMessage("One of the fields is invalid, please try again");
      setDisplayAlert(true);
    } else if (validatePassword(newPassword)) {
      const request = {
        user_ID: userID,
        new_password: newPassword,
      };
      sendPostRequest(Urls.setPassword, request)
        .then((response) => {
          //  debugger
          if (response && response.status) {
            setIsSetPasswordModal(false);
            setNewPassword("");
            setConfirmPassword("");
            setDisplayAlert(true);
            setErrorMessage("Password has been changed succesfully");
            // Cookies.set("userID", userID, { expires: 30 })
            // //console.log(Cookies.get("userID"))
          } else {
            setErrorMessage("Failed to set password, please try again");
            setDisplayAlert(true);
          }
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
        });
    } else {
      setErrorMessage(
        "Password must contain one lowercase letter, one uppercase letter, one digit and should be more than 7 characters"
      );
      setDisplayAlert(true);
    }
  };

  const handleContactUs = () => {
    setIsContactUs(true);
  };

  const handleCheckEmail = (event) => {
    setEmail(event.target.value);
    if (validateEmail(email)) {
      setIsCompareEmail(false);
    } else {
      setIsCompareEmail(true);
    }
  }

  const handleCloseContactUs = () => {
    setName("");
    setEmail("");
    setNumber("");
    setCompany("");
    setMessage("");
    setIsContactUs(false);
  };

  const verifyMessage = () => {
    if (name === "" || email === "" || company === "") {
      return false;
    }
    return true;
  };

  const handleSendMessage = () => {
    setLoading(true);
    if (verifyMessage()) {
      let data = {
        contactLog: {
          email_ID: email,
          name: name,
          phone: number,
          company_name: company,
          message: message,
        },
      };
      sendPostRequest(Urls.contactUs, data)
        .then((response) => {
          if (!response) {
            setErrorMessage("Please check your network and try again later.");
            setDisplayAlert(true);
          } else {
            setErrorMessage(
              "Thank you for reaching out! We'll get back to you soon."
            );
            setDisplayAlert(true);
            handleCloseContactUs();
          }
          setLoading(false);
        })
        .catch((err) => {
          //console.log("Error:", err);
          setErrorMessage("Please check your network and try again later.");
          setDisplayAlert(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setErrorMessage("One of the required fields is empty!");
      setDisplayAlert(true);
    }
  };

  return (
    <Fragment>
      <Grid container className={style.root}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} className={style.container_left}>
            <img
              src={require("../assets/image/VEngageBlueLogo.png")}
              className={style.image}
              alt="VDart logo"
            />
            <br />
            <br />
            <Grid item className={style.credential_container}>
              <Typography className={style.title} variant="h4">
                <b>Login using VDart account</b>{" "}
              </Typography>
              <br />
              <br />
              <TextField
                className={style.text}
                label="Work Email"
                variant="outlined"
                onChange={handleInput}
              />
              <br />
              <br />
              <TextField
                className={style.text}
                type="password"
                label="Password"
                variant="outlined"
                onChange={handleInputPassword}
                value={password}
              />
              <br />
              <br />
              <Button
                className={style.button}
                variant="contained"
                onClick={handleLogin}
              >
                Login
              </Button>
              <br />
              <br />
              <InputLabel
                className={style.forgotPassword}
                onClick={handleForgotPassword}
              >
                <Link href="#">Forgot Password?</Link>
              </InputLabel>
            </Grid>
          </Paper>
          {/* generate OTP dialog */}
          <Dialog open={isGenerateOtpModal} onClose={handleClose}>
            <DialogTitle>
              <Typography className={style.dialogTitle} variant="h5">
                Forgot Password
              </Typography>
              <IconButton className={style.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <br></br>
              <Typography className={style.dialogTitleMessage}>
                Don't worry. Resetting your password is easy. Just enter your
                registered email address and an One Time Password will be sent
                to you.
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                className={style.textField}
                label="Work Email"
                variant="outlined"
                onChange={handleInput}
                value={userID}
              />
            </DialogContent>
            <DialogActions dividers>
              <Button
                autoFocus
                onClick={handleGenerateOtp}
                className={style.blueButton}
              >
                Generate OTP
              </Button>
            </DialogActions>
          </Dialog>
          {/* verify OTP dialog */}
          <Dialog open={isVerifyOtpModal} onClose={handleCloseVerifyOtpModal}>
            <DialogTitle>
              <Typography className={style.dialogTitle} variant="h5">
                Forgot Password
              </Typography>
              <IconButton
                className={style.closeButton}
                onClick={handleCloseVerifyOtpModal}
              >
                <CloseIcon />
              </IconButton>
              <br></br>
              <Typography className={style.dialogTitleMessage}>
                Enter the One Time Password that was sent via email. OTP will be valid for the next 15 minutes.
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                className={style.textField}
                label="Please Enter the OTP"
                variant="outlined"
                onChange={handleInputVerifyOtp}
                value={verifyOtp}
              />
            </DialogContent>
            <DialogActions dividers>
              <Button
                autoFocus
                onClick={handleGenerateOtp}
                className={style.blueButton}
              >
                Resend OTP
              </Button>
              <Button
                autoFocus
                onClick={handleVerifyOtpModal}
                className={style.orangeButton}
              >
                Verify OTP
              </Button>
            </DialogActions>
          </Dialog>
          {/* set password dialog */}
          <Dialog
            open={isSetPasswordModal}
            onClose={handleCloseSetPasswordModal}
          >
            <DialogTitle>
              <Typography className={style.dialogTitle} variant="h5">
                Forgot Password
              </Typography>
              <IconButton
                className={style.closeButton}
                onClick={handleCloseSetPasswordModal}
              >
                <CloseIcon />
              </IconButton>
              <br></br>
              <Typography className={style.dialogTitleMessage}>
                Just tell us the new password.
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                className={style.textField}
                type="password"
                label="New Password"
                onChange={handleNewPassword}
                value={newPassword}
              />
              <span
                className={style.passwordError}
                style={
                  isCompareOldPassword
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                New Password should not match with the previous password. Please
                try again.
              </span>
              <TextField
                className={style.textField}
                type="password"
                label="Confirm Password"
                onChange={handleConfirmPassword}
                value={confirmPassword}
              />
              <span
                className={style.Password}
                style={
                  isComparePassword ? { display: "block" } : { display: "none" }
                }
              >
                Your Current Password does not match with the password you
                provided. Please try again.
              </span>
            </DialogContent>
            <DialogActions dividers>
              <Button
                autoFocus
                onClick={handleSetPassword}
                className={style.blueButton}
              >
                Set Password
              </Button>
            </DialogActions>
          </Dialog>
          {/* Contact Us dialog */}
          <Dialog open={isContactUs} onClose={handleCloseContactUs}>
            <DialogTitle>
              <Typography className={style.dialogTitle} variant="h5">
                Ready to embrace the future?
              </Typography>
              <IconButton
                className={style.closeButton}
                onClick={handleCloseContactUs}
              >
                <CloseIcon />
              </IconButton>
              <br></br>
              <Typography className={style.dialogTitleMessage} variant="h7">
                We appreciate your interest in V Engage. Please fill out the
                form and we’ll respond to you as soon as possible.
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                required
                className={style.textField}
                label="Full Name"
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
              <TextField
                required
                className={style.textField}
                label="Email"
                onChange={handleCheckEmail}
                value={email} />
              <span
                className={style.passwordError}
                style={
                  isCompareEmail
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                Please enter a valid email address
              </span>
              <TextField
                className={style.textField}
                label="Contact Number"
                onChange={(event) => setNumber(event.target.value)}
                value={number}
              />
              <TextField
                required
                className={style.textField}
                label="Company"
                onChange={(event) => setCompany(event.target.value)}
                value={company}
              />
              <TextField
                multiline
                rowsMax={5}
                className={style.textField}
                label="Message"
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </DialogContent>
            <DialogActions dividers>
              <Button
                autoFocus
                disabled={loading}
                onClick={handleSendMessage}
                className={style.blueButton}
              >
                Send
              </Button>
              {loading && (
                <CircularProgress size={24} className={style.buttonProgress} />
              )}
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid className={style.container_right} item xs={12} md={4}>
          <CssBaseline />
          <Paper elevation={0} className={style.container_blue}>
            <Typography style={{ width: "80%" }}>
              <Typography className={style.container_blueTitle} variant="h4">
                V Engage Dashboard<br></br>
              </Typography>
              A single interface for talent management that thrives to increase
              efficiency and reduce time-to-onboard.<br></br>
              <br></br>
              Features :<br></br>• Candidate Lists: View, update and track a
              candidate recruitment lifecycle <br></br>• Approval Center: Mange
              candidate start date and end date<br></br>• Surveys: Create,
              update and view survey for candidate feedback<br></br>• Send
              Notification: Communicate with candidates by sending messages on
              app<br></br>• Reports: View interactive reports on surveys, skills
              and user engagement with app
            </Typography>
            <br></br>
            <Typography className={style.container_blueTitle}>
              Would like to adopt V Engage for your organisation?
              <InputLabel onClick={handleContactUs}>
                <Link style={{ color: color.white }} href="#">
                  Click here and we'll get in touch!
                </Link>
              </InputLabel>
            </Typography>
          </Paper>
          <Grid item>
            <CssBaseline />
            <Paper elevation={0} className={style.container_orange}>
              {/* <Typography>
                Would like to adopt V Engage for your organisation?
                <InputLabel onClick={handleContactUs}><Link href="#">Click here and we'll get in touch!</Link></InputLabel>
              </Typography>
              <br></br> */}
              <Typography style={{ width: "80%" }}>
                © V Engage 2020. All Rights Reserved.<br></br>Powered By{" "}
                <a
                  target="_blank"
                  href="https://www.dimiour.io"
                >
                  Dimiour
                </a>
                <br></br>Supported By {""}
                <a target="_blank" href="mailto:support@vengage.io">
                  support@vengage.io
                </a>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <SystemAlert
        open={displayAlert}
        handleAlert={handleAlert}
        message={errorMessage}
      />
    </Fragment>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100vh",
  },
  title: {
    width: "60%",
    textAlign: "center",
  },
  text: {
    width: "50%",
    height: 50,
  },
  textField: {
    width: "100%",
  },
  Password: {
    color: color.red,
  },
  image: {
    marginLeft: "2%",
    marginTop: "2%",
    height: "12%",
    width: "10%",
  },
  dialogTitle: {
    color: color.black,
  },
  dialogTitleMessage: {
    color: color.blue,
    fontSize: 14,
  },
  button: {
    width: "50%",
    color: color.white,
    backgroundColor: color.red,
    "&:hover": {
      backgroundColor: "rgb(245,0,20)",
    },
  },
  blueButton: {
    color: color.white,
    backgroundColor: color.blue,
    "&:hover": {
      backgroundColor: color.blue,
    },
  },
  orangeButton: {
    color: color.white,
    backgroundColor: color.orange,
    "&:hover": {
      backgroundColor: color.orange,
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    float: "right",
    top: "-30px",
  },
  container_left: {
    height: "100%",
  },
  container_right: {
    backgroundColor: color.orange,
  },
  credential_container: {
    paddingTop: "5%",
    textAlign: "center",
    borderRadius: 0,
    backgroundColor: color.white,
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container_blueTitle: {
    textAlign: "center",
  },
  container_blue: {
    textAlign: "left",
    color: color.white,
    backgroundColor: color.blue,
    borderRadius: 0,
    height: "78%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container_orange: {
    textAlign: "center",
    color: color.white,
    borderRadius: 0,
    backgroundColor: color.orange,
    height: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "15%",
  },
  container_orangTitle: {
    textAlign: "top",
  },
  // Progress bar while clicking button
  buttonProgress: {
    color: color.white,
    position: "absolute",
    top: "92%",
    left: "90%",
    // marginTop: -5,
    // marginLeft: -5,
  },
  passwordError: {
    color: color.red,
  }
}));
