//Function to check password
function validatePassword(password) {
  let reg = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/; // one letter and one digit with minimum length of 8 characters
  //console.log('Checking Password: ',password)

  var status;
  reg.test(password) ? (status = true) : (status = false);

  return status;
}

//Function to check email format
function validateEmail(email) {
  let reg;
  if (email.indexOf("@") !== -1) {
    reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //email format validation
  } else {
    reg = /^[V][D][A-Z][A-Z][0-9]/;
  }
  //console.log('Checking Email: ',email)

  var status;
  reg.test(email) ? (status = true) : (status = false);

  return status;
}

//Funtion to send Post Request; Accepts the url and the paramList in JSON format
function sendPostRequest(url, paramList) {
  return new Promise((resolve, reject) => {
    var params = JSON.stringify(paramList);
    fetch(url, {
      method: "post",
      mode: "cors",
      headers: { 
        'Access-Control-Allow-Origin':'*',
        "Content-Type": "application/json" 
      },
      body: params,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log('API Response: ', responseJson)
        responseJson.status
          ? resolve(responseJson)
          : responseJson === 404
          ? reject(null)
          : resolve(false);
      })
      .catch((error) => {
        console.log("Error while fetching Data: ", error);
        reject(null);
      });
  });
}

export { validateEmail, validatePassword, sendPostRequest };
