import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Typography, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { color } from "../assets/color";
import { sendPostRequest, validatePassword } from '../Actions';
// import ProgressBar from "./ProgressBar";
import SystemAlert from "./SystemAlert";
import Urls from '../Urls';

export default function ChangePassword(props) {
    const style = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // const [progressBar, setProgressBar] = useState(true);
    // const [loading, setLoading] = React.useState(false);
    // const [success, setSuccess] = React.useState(false);
    // const timer = React.useRef();
    const [isCompareOldPassword, setIsCompareOldPassword] = useState(false);
    const [isComparePassword, setIsComparePassword] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setIsCompareOldPassword(false);
        setIsComparePassword(false);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const handleCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
        if (event.target.value === currentPassword) {
            setIsCompareOldPassword(true);
        } else {
            setIsCompareOldPassword(false);
        }
        if (event.target.value !== confirmPassword) {
            setIsComparePassword(true);
        } else {
            setIsComparePassword(false);
        }
    };

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
        if (newPassword !== event.target.value) {
            setIsComparePassword(true);
        } else {
            setIsComparePassword(false);
        }
    };

    const handleSetPassword = () => {
        //console.log("changePassword ")
        // setProgressBar(true);
        if (isComparePassword || isCompareOldPassword) {
            setErrorMessage("One of the fields is invalid, please try again")
            setDisplayAlert(true)
        } else if (validatePassword(newPassword)) {
            let data = {
                "user_ID": props.userID,
                "old_password": currentPassword,
                "new_password": newPassword,
            }
            //console.log(data)
            sendPostRequest(Urls.changePassword, data).then(response => {
                //console.log("changePassword Response: ", response)
                if (!response) {
                    setErrorMessage("Failed to set password, please try again");
                    setDisplayAlert(true);
                } else {
                    setOpen(false);
                    //console.log(response)
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    props.handleSetPasswordMessage();
                    // setProgressBar(false);
                }
            }).catch(err => {
                //console.log("Error:", err);
                setErrorMessage("Please check the network and try again later.");
                setDisplayAlert(true);
                // setProgressBar(false);
            })
        } else {
            setErrorMessage("Password must contain one lowercase letter, one uppercase letter, one digit and should be more than 7 characters");
            setDisplayAlert(true);
        }
    };

    const handleAlert = () => {
        setDisplayAlert(!displayAlert)
        setErrorMessage("")
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} className={style.passwordButton} >
                Change Password
            </Button>
            <Dialog onClose={handleClose} open={open} className={style.dialog}>
                <DialogTitle className={style.dialogTitle} >
                    <Typography > Change Password</Typography>
                    <IconButton
                        className={style.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField className={style.password} type="password" label="Current Password" onChange={handleCurrentPassword} value={currentPassword} />
                    <TextField className={style.password} type="password" label="New Password" onChange={handleNewPassword} value={newPassword} />
                    <span className={style.passwordError} style={isCompareOldPassword ? { display: 'block' } : { display: 'none' }}>New Password should not match with the previous password. Please try again.</span>
                    <TextField className={style.password} type="password" label="Confirm Password" onChange={handleConfirmPassword} value={confirmPassword} />
                    <span className={style.passwordError} style={isComparePassword ? { display: 'block' } : { display: 'none' }}>Current Password does not match with the password you provided. Please try again.</span>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSetPassword} className={style.passwordButton} >
                        Set Password
                    </Button>
                </DialogActions>
                <SystemAlert open={displayAlert} handleAlert={handleAlert} message={errorMessage} />
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    dialog: {
        width: "100%"
    },
    dialogTitle: {
        color: color.black,
        variant: "h6"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    password: {
        width: "100%",
        marginTop: "2%",
    },
    passwordButton: {
        marginTop: "5%",
        color: color.white,
        backgroundColor: color.orange,
        "&:hover": {
            backgroundColor: color.orange,
        },
    },
    buttonProgress: {
        color: color.red,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        // display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'absolute',
    },
    passwordError: {
        color: color.red,
    }
}));
