import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  AppBar,
  Typography,
  Toolbar,
  Button,
  Divider,
  Fab,
  InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { color } from "../assets/color";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import ApprovalModal from "./ApprovalModal";
import Moment from "moment";
import { sendPostRequest } from "../Actions";
import SystemAlert from "./SystemAlert";
import Urls from "../Urls";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ProgressBar from "./ProgressBar";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { ExportCSV } from "./ExportCSV";

export default function ApprovalTable(props) {
  const style = useStyles();

  const [approvalModal, setApprovalModal] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [approvalDataForModal, setApprovalDataForModal] = useState({});
  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [progressBar, setProgressBar] = useState(true);
  const [dateDownArrow, setDateDownArrow] = useState(false);
  const [nameDownArrow, setNameDownArrow] = useState(false);
  const [plcCodeDownArrow, setPlcCodeDownArrow] = useState(false);
  const fileName = "V Engage Approval Data_" + props.title;

  const handleClick = (event, approval) => {
    setApprovalModal(true);
    setApprovalDataForModal(approval);
  };

  const handleClose = () => {
    setApprovalModal(!approvalModal);
  };

  const handleApprovalCheckBox = (event, index) => {
    var updatedApprovalData = [];
    candidateList.map((candidate) => {
      if (candidate.index === index) {
        //console.log(candidate.temp_start_date);
        if (
          (props.title === "Start Date" && !candidate.temp_start_date) ||
          (props.title === "End Date" && !candidate.temp_end_date)
        ) {
          setErrorMessage("Please select a valid date");
          setDisplayAlert(true);
        } else if (
          candidate.approver_name === null ||
          candidate.approver_name.trim() === ""
        ) {
          setErrorMessage("Please add approver name");
          setDisplayAlert(true);
        } else {
          candidate.isApproved = event.target.checked;
        }
      }
      updatedApprovalData.push(candidate);
    });
    setCandidateList(updatedApprovalData);
  };

  const handleDisapprovalCheckBox = (event, index) => {
    var updatedDisapprovalData = [];
    candidateList.map((candidate) => {
      if (candidate.index === index) {
        //console.log(candidate.temp_start_date);
        if (
          (props.title === "Start Date" && !candidate.temp_start_date) ||
          (props.title === "End Date" && !candidate.temp_end_date)
        ) {
          setErrorMessage("Please select a valid date");
          setDisplayAlert(true);
        } else {
          candidate.isDisapproved = event.target.checked;
        }
      }
      updatedDisapprovalData.push(candidate);
    });
    setCandidateList(updatedDisapprovalData);
  };

  const handleApproverName = (event, index) => {
    var updatedApprovalData = [];
    candidateList.map((candidate) => {
      if (candidate.index === index) {
        candidate.approver_name = event.target.value;
      }
      updatedApprovalData.push(candidate);
    });
    setCandidateList(updatedApprovalData);
  };

  const handleSend = () => {
    let filteredApprovalData = candidateList.filter(
      (f) => f.isApproved === true
    );
    let filteredDisapprovalData = candidateList.filter(
      (f) => f.isDisapproved === true
    );
    let selectedCandidates = [];
    let requestUrl = "";
    if (filteredApprovalData.length > 0 || filteredDisapprovalData.length > 0) {
      if (filteredApprovalData.length > 0) {
        if (props.title === "Start Date") {
          requestUrl = Urls.approveStartDate;
        } else {
          requestUrl = Urls.approveEndDate;
        }
        filteredApprovalData.forEach((element) => {
          if (props.title === "Start Date") {
            element.start_date = element.newDate
              ? element.newDate
              : element.temp_start_date;
          } else {
            element.actual_end_date = element.newDate
              ? element.newDate
              : element.temp_end_date;
            element.show_end_date_card = element.newDate ? true : false;
          }
          selectedCandidates.push(element);
        });
      }
      if (filteredDisapprovalData.length > 0) {
        if (props.title === "Start Date") {
          requestUrl = Urls.approveStartDate;
        } else {
          requestUrl = Urls.approveEndDate;
        }
        filteredDisapprovalData.forEach((element) => {
          if (props.title === "Start Date") {
            element.temp_start_date = element.start_date;
          } else {
            element.temp_end_date = element.actual_end_date;
          }
          //console.log(element);
          selectedCandidates.push(element);
        });
      }
      let request = {
        user_id: props.userID,
        selectedCandidates: selectedCandidates,
      };
      sendPostRequest(requestUrl, request)
        .then((response) => {
          // console.log(response);
          if (response) {
            if (response.status) {
              setErrorMessage("Updated successfully");
              props.updateBadgeCount();
            } else {
              setErrorMessage(
                "Failed to approve dates for candidates with email_ID : " +
                response.data
              );
            }
            setDisplayAlert(true);
            getApprovalDetails();
          } else {
            setErrorMessage("Something went wrong. Please try again later.");
            setDisplayAlert(true);
          }
          setProgressBar(false);
        })
        .catch((err) => {
          // console.log("Error:", err);
          setErrorMessage("Please check the network and try again later.");
          setDisplayAlert(true);
          setProgressBar(false);
        });
    } else {
      setErrorMessage("Please select at least one candidate");
      setDisplayAlert(true);
    }
  };

  const handleDate = (event, index) => {
    //console.log(event)
    var updatedApprovalData = [];
    candidateList.map((candidate) => {
      if (candidate.index === index) {
        candidate.newDate = Moment(event._d).format("MM/DD/YYYY");
        //console.log(candidate.newDate)
        if (props.title === "Start Date") {
          candidate.temp_start_date = candidate.newDate;
        } else {
          candidate.temp_end_date = candidate.newDate;
        }
      }
      updatedApprovalData.push(candidate);
    });
    setCandidateList(updatedApprovalData);
  };

  const getApprovalDetails = () => {
    let data = {
      user_id: props.userID,
      type: "Start Date",
    };
    sendPostRequest(Urls.getCandidatesForApproval, data)
      .then((response) => {
        // console.log("getCandidatesForApproval ", response);
        if (response && response.status) {
          let index = 0;
          response.data.map((m) => {
            index = index + 1;
            m["index"] = index;
            m["isApproved"] = false;
          });
          if (props.title === "Start Date") {
            setCandidateList(response.data);
            setProgressBar(false);
          }
          props.setStartDateCount(response.data.length);
        } else {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
    data = {
      user_id: props.userID,
      type: "End Date",
    };
    sendPostRequest(Urls.getCandidatesForApproval, data)
      .then((response) => {
        // console.log("getCandidatesForApproval ", response);
        if (response && response.status) {
          let index = 0;
          response.data.map((m) => {
            index = index + 1;
            m["index"] = index;
            m["isApproved"] = false;
          });
          if (props.title === "End Date") {
            setCandidateList(response.data);
            setProgressBar(false);
          }
          props.setEndDateCount(response.data.length);
          // setProgressBar(false);
        } else {
          setErrorMessage("Something went wrong. Please try again later.");
          setDisplayAlert(true);
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        setErrorMessage("Please check the network and try again later.");
        setDisplayAlert(true);
        setProgressBar(false);
      });
  };

  // const getStartDateCount = () => {
  //   let data = {
  //     user_id: props.userID,
  //     type: "Start Date",
  //   };
  //   sendPostRequest(Urls.getCandidatesForApproval, data)
  //     .then((response) => {
  //       // console.log("getCandidatesForApproval ", response);
  //       if (response && response.status) {
  //         let index = 0;
  //         response.data.map((m) => {
  //           index = index + 1;
  //           m["index"] = index;
  //           m["isApproved"] = false;
  //         });
  //         if (props.title === "Start Date") {
  //           setCandidateList(response.data);
  //           setProgressBar(false);
  //         }
  //         props.setStartDateCount(response.data.length);
  //         // setProgressBar(false);
  //       } else {
  //         setErrorMessage("Something went wrong. Please try again later.");
  //         setDisplayAlert(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error:", err);
  //       setErrorMessage("Please check the network and try again later.");
  //       setDisplayAlert(true);
  //       setProgressBar(false);
  //     });
  // };

  // const getEndDateCount = () => {
  //   let data = {
  //     user_id: props.userID,
  //     type: "End Date",
  //   };
  //   sendPostRequest(Urls.getCandidatesForApproval, data)
  //     .then((response) => {
  //       // console.log("getCandidatesForApproval ", response);
  //       if (response && response.status) {
  //         let index = 0;
  //         response.data.map((m) => {
  //           index = index + 1;
  //           m["index"] = index;
  //           m["isApproved"] = false;
  //         });
  //         if (props.title === "End Date") {
  //           setCandidateList(response.data);
  //           setProgressBar(false);
  //         }
  //         props.setEndDateCount(response.data.length);
  //         // setProgressBar(false);
  //       } else {
  //         setErrorMessage("Something went wrong. Please try again later.");
  //         setDisplayAlert(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error:", err);
  //       setErrorMessage("Please check the network and try again later.");
  //       setDisplayAlert(true);
  //       setProgressBar(false);
  //     });
  // };

  useEffect(() => {
    getApprovalDetails();
    // getStartDateCount();
    // getEndDateCount();
  }, []);

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  const disablePrevDates = (start_date, actual_end_date) => {
    if (props.title === "End Date") {
      if (start_date) {
        var startDate = new Date(start_date);
        startDate.setDate(startDate.getDate() + 1);
        const startSeconds = Date.parse(startDate);
        return (date) => {
          return Date.parse(date) < startSeconds;
        };
      } else {
        const startSeconds = Date.parse(new Date("01/01/2000"));
        return (date) => {
          return Date.parse(date) < startSeconds;
        };
      }
    } else {
      if (actual_end_date) {
        var endDate = new Date(actual_end_date);
        endDate.setDate(endDate.getDate() - 1);
        const startSeconds = Date.parse(endDate);
        return (date) => {
          return Date.parse(date) > startSeconds;
        };
      } else {
        const startSeconds = Date.parse(new Date("12/31/4000"));
        return (date) => {
          return Date.parse(date) > startSeconds;
        };
      }
    }
  };

  const sortDate = () => {
    var sortedList = candidateList;
    if (!dateDownArrow) {
      if (props.title === "Start Date") {
        sortedList.sort((a, b) => {
          return (
            new Date(a.temp_start_date).getTime() -
            new Date(b.temp_start_date).getTime()
          );
        });
      } else {
        sortedList.sort((a, b) => {
          return (
            new Date(a.temp_end_date).getTime() -
            new Date(b.temp_end_date).getTime()
          );
        });
      }
    } else {
      if (props.title === "Start Date") {
        sortedList.sort((a, b) => {
          return (
            new Date(b.temp_start_date).getTime() -
            new Date(a.temp_start_date).getTime()
          );
        });
      } else {
        sortedList.sort((a, b) => {
          return (
            new Date(b.temp_end_date).getTime() -
            new Date(a.temp_end_date).getTime()
          );
        });
      }
    }
    setCandidateList(sortedList);
    setDateDownArrow(!dateDownArrow);
  };

  const sortName = () => {
    var sortedList = candidateList;
    if (!nameDownArrow) {
      sortedList.sort((a, b) => {
        if (a.candidate_name > b.candidate_name) {
          return -1;
        }
        if (b.candidate_name > a.candidate_name) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList.sort((a, b) => {
        if (a.candidate_name > b.candidate_name) {
          return 1;
        }
        if (b.candidate_name > a.candidate_name) {
          return -1;
        }
        return 0;
      });
    }
    setCandidateList(sortedList);
    setNameDownArrow(!nameDownArrow);
  };

  const sortPlcCode = () => {
    var sortedList = candidateList;
    if (!plcCodeDownArrow) {
      sortedList.sort((a, b) => {
        if (a.plc_code > b.plc_code) {
          return -1;
        }
        if (b.plc_code > a.plc_code) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList.sort((a, b) => {
        if (a.plc_code > b.plc_code) {
          return 1;
        }
        if (b.plc_code > a.plc_code) {
          return -1;
        }
        return 0;
      });
    }
    setCandidateList(sortedList);
    setPlcCodeDownArrow(!plcCodeDownArrow);
  };

  return (
    <Fragment>
      {progressBar ? (
        <ProgressBar />
      ) : (
          <Fragment>
            <Fab
              variant="extended"
              className={style.fab}
              variant="contained"
              onClick={handleSend}
            >
              Send
          </Fab>
            <AppBar position="static" className={style.appbar}>
              <Toolbar>
                {/* <Badge
                  badgeContent={props.title === 'Start Date' ? startDateCount : endDateCount}
                  max={999}
                  color="secondary"
                > */}
                <div style={{ borderWidth: 1 }}>
                  <Typography className={style.title} variant="h6">
                    {props.title}
                  </Typography>
                </div>
                {/* </Badge> */}
                {candidateList && (
                  <ExportCSV
                    csvData={candidateList.filter(
                      (candidateData) =>
                        (candidateData.candidate_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                          // candidateData.email_ID
                          //   .toLowerCase()
                          //   .includes(search.toLowerCase()) ||
                          candidateData.plc_code
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                    )}
                    fileName={fileName}
                  />
                )}
                <div className={style.search}>
                  <div className={style.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: style.searchText,
                      input: style.searchBox,
                    }}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
              </Toolbar>
              <Divider />
              <Table className={style.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={style.tableHead}
                      style={{ minWidth: 125 }}
                    >
                      <div>
                        PLC Code
                      {plcCodeDownArrow ? (
                          <ArrowDropDown onClick={(event) => sortPlcCode()} />
                        ) : (
                            <ArrowDropUp onClick={(event) => sortPlcCode()} />
                          )}
                      </div>
                    </TableCell>
                    <TableCell
                      className={style.tableHead}
                      style={{ minWidth: 250 }}
                    >
                      <div>
                        Candidate
                      {nameDownArrow ? (
                          <ArrowDropDown onClick={(event) => sortName()} />
                        ) : (
                            <ArrowDropUp onClick={(event) => sortName()} />
                          )}
                      </div>
                    </TableCell>
                    {/* <TableCell className={style.tableHead}>Email</TableCell>
                    <TableCell className={style.tableHead}>Phone</TableCell> */}
                    <TableCell
                      className={style.tableHead}
                      style={{ minWidth: 100 }}
                    >
                      <div>
                        Entered {props.title}
                        {dateDownArrow ? (
                          <ArrowDropDown onClick={(event) => sortDate()} />
                        ) : (
                            <ArrowDropUp onClick={(event) => sortDate()} />
                          )}
                      </div>
                    </TableCell>
                    <TableCell className={style.tableHead}>Approve</TableCell>
                    <TableCell className={style.tableHead}>Disapprove</TableCell>
                    <TableCell
                      className={style.tableHead}
                      style={{ minWidth: 200 }}
                    >
                      Approved By
                  </TableCell>
                  <TableCell
                      className={style.tableHead}
                      style={{ minWidth: 150 }}
                    >
                      New {props.title}
                  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {candidateList &&
                    candidateList
                      .filter(
                        (candidateData) =>
                          candidateData.candidate_name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          // candidateData.email_ID
                          //   .toLowerCase()
                          //   .includes(search.toLowerCase()) ||
                          candidateData.plc_code
                            .toLowerCase()
                            .includes(search.toLowerCase())
                      )
                      .map((approval) => (
                        <TableRow className={style.cell}>
                          <TableCell>
                            <Button style={{ textTransform: "none" }}>
                              {approval.plc_code}
                            </Button>
                          </TableCell>
                          <TableCell
                            onClick={(event) => handleClick(event, approval)}
                            key={approval.email}
                          >
                            <Button style={{ textTransform: "none" }}>
                              {approval.candidate_name}
                            </Button>
                          </TableCell>
                          {/* <TableCell
                            onClick={(event) => handleClick(event, approval)}
                            key={approval.email}
                          >
                            {approval.email_ID}
                          </TableCell> */}
                          {/* <TableCell
                            onClick={(event) => handleClick(event, approval)}
                            key={approval.email}
                          >
                            {approval.phone}
                          </TableCell> */}
                          <TableCell
                            onClick={(event) => handleClick(event, approval)}
                            key={approval.email}
                          >
                            {props.title === "Start Date"
                              ? approval.temp_start_date
                                ? Moment(
                                  new Date(approval.temp_start_date)
                                ).format("MM/DD/YYYY")
                                : "N/A"
                              : approval.temp_end_date
                                ? Moment(new Date(approval.temp_end_date)).format(
                                  "MM/DD/YYYY"
                                )
                                : "N/A"}
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={approval.isApproved}
                              disabled={approval.isDisapproved}
                              onChange={(event) =>
                                handleApprovalCheckBox(event, approval.index)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={approval.isDisapproved}
                              disabled={approval.isApproved}
                              onChange={(event) =>
                                handleDisapprovalCheckBox(event, approval.index)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              className={style.approverName}
                              placeholder="Enter Approver Name"
                              value={approval.approver_name}
                              onChange={(event) =>
                                handleApproverName(event, approval.index)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker
                                shouldDisableDate={disablePrevDates(
                                  approval.start_date,
                                  approval.actual_end_date
                                )}
                                label="MM/DD/YYYY"
                                value={approval.newDate}
                                className={style.date}
                                format="MM/DD/YYYY"
                                onChange={(event) =>
                                  handleDate(event, approval.index)
                                }
                              />
                              {/* {console.log(approval.newDate)} */}
                            </MuiPickersUtilsProvider>
                            {/* <TextField
                                        className={style.date}
                                        type="date"
                                        onChange={event => handleDate(event, approval.index)}
                                    /> */}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </AppBar>
            {/* <Fab variant="extended" className={style.button} onClick={handleApproveAndSend}>Approve & Send</Fab> */}
            <ApprovalModal
              approval={approvalDataForModal}
              open={approvalModal}
              handleClose={handleClose}
              type={props.title}
            />
            <SystemAlert
              open={displayAlert}
              handleAlert={handleAlert}
              message={errorMessage}
            />
          </Fragment>
        )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  //table
  table: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: color.white,
    color: color.blue,
  },
  cell: {
    backgroundColor: "white",
    fontSize: 10,
    align: "left",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  //App bar with search icon
  appbar: {
    backgroundColor: color.white,
    width: "100%",
    alignItems: "left",
    //marginTop: "15%"
  },
  title: {
    color: color.red,
  },
  search: {
    position: "absolute",
    marginLeft: "76%",
    width: "20%",
  },
  searchIcon: {
    padding: "3%",
    height: "100%",
    width: "20%",
    backgroundColor: color.grey,
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    marginLeft: "-13%",
    justifyContent: "center",
  },
  searchText: {
    color: color.black,
  },
  searchBox: {
    width: "100%",
    borderRadius: 10,
    marginLeft: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: color.grey,
  },
  //approve button
  button: {
    color: color.white,
    backgroundColor: color.orange,
    //marginLeft: "99%",
    marginTop: "7%",
    marginBottom: "2%",
    width: "15%",
    "&:hover": {
      backgroundColor: color.orange,
    },
  },
  fab: {
    color: color.white,
    backgroundColor: color.orange,
    marginLeft: "76%",
    marginTop: "5%",
    marginBottom: "2%",
    width: "7%",
    "&:hover": {
      backgroundColor: color.orange,
    },
    position: "fixed",
  },
  date: {
    width: "80%",
  },
  approverName: {
    marginTop: "8%",
  },
  // badgeCountBox: {
  //   color: color.white,
  //   backgroundColor: color.orange,
  //   marginLeft: "76%",
  //   marginTop: "1%",
  //   marginBottom: "2%",
  //   width: "8%",
  //   "&:hover": {
  //     backgroundColor: color.orange,
  //   },
  //   position: "fixed",
  // },
}));
