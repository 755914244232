const prod = "https://vengage.io"; //server URL
const test = "http://vengage-server-test.ap-south-1.elasticbeanstalk.com"; //test url

const server= prod + "/server";

export default {
  login: server + "/dashboard/login/", //user_ID , user_password
  viewCandidate: server + "/viewCandidate/", //user_ID , type
  updateCandidateStatus: server + "/updateCandidateStatus/", //"user_ID, selected_candidates[{email_ID,candidate_name, dob, phone, address, job_title, start_date, temp_start_date, end_date, temp_end_date, candidate_status, isBenched, isBlacklistedisNewUser,skills,domain}...]
  sendMessage: server + "/sendMessage/", //user_ID, candidate_type, message{}
  viewSurvey: server + "/viewSurvey/", //user_ID
  getSettings: server + "/getSettings/",
  updateSettings: server + "/updateSettings/",
  createSurvey: server + "/createSurvey/", //user_ID, survey{survey_name.survey_type,description,questions[question,answer_id]...}
  getAnswers: server + "/getAnswers/", //user_ID
  changePassword: server + "/dashboard/changePassword/",
  getCandidatesForApproval: server + "/getCandidatesForApproval/",
  approveStartDate: server + "/approveStartDate/",
  approveEndDate: server + "/approveEndDate/",
  editSurvey: server + "/editSurvey/", //user_ID, survey{_id,survey_name,survey_type,description,questions[question,answer_id]...}
  addAnswer: server + "/addAnswer/", //user_ID, answer{type,options,added_by,added_at}
  getUserProfile: server + "/getUserProfile/", //user_ID
  generateOtp: server + "/dashboard/generateOtp/",
  verifyOtp: server + "/dashboard/verifyOtp/",
  setPassword: server + "/dashboard/setPassword/",
  contactUs: server + "/dashboardContactUs/",
  updateCandidateDate: server + "/updateCandidateDate/",
  validateCsv: server + "/smsSender/validateCsv/",
  smsBlast: server + "/smsSender/smsBlast/",
  csvUpload: server + "/smsSender/upload/"
};
