import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import { color } from "../assets/color";
import ApprovalSetting from "./ApprovalSetting";
import ApprovalTable from "./ApprovalTable";
import SystemAlert from "./SystemAlert";
import RefreshIcon from '@material-ui/icons/Refresh';

export default function ApprovalCenter(props) {
  const style = useStyles();

  const [selectedTab, setSelectedTab] = useState("Start Date");
  const [approvalTableData, setApprovalTableData] = useState(
    <></>
    // <ApprovalTable
    //   title="Start Date"
    //   userID={props.userID}
    //   updateBadgeCount={props.updateBadgeCount}
    //   dateCount={setStartDateCount}
    // />
  );
  const [displayAlert, setDisplayAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDateCount, setStartDateCount] = React.useState(0);
  const [endDateCount, setEndDateCount] = React.useState(0);

  useEffect(() => {
    // setApprovalTableData(
    //   <ApprovalTable
    //     title="Start Date"
    //     userID={props.userID}
    //     updateBadgeCount={props.updateBadgeCount}
    //     dateCount={setStartDateCount}
    //   />
    // )

    tabClick("Start Date");

    // let data = {
    //   "user_id": props.userID,
    //   "type": "Confirmed"
    // }
    // console.log(data)
    // sendPostRequest(Urls.viewCandidate, data).then(response => {
    //   console.log('Response ', response)
    //   if (!response) {
    //     setErrorMessage("Something went wrong. Please try again later.")
    //     setDisplayAlert(true)
    //   } else {
    //     setApprovalTableData(<ApprovalTable title="Start Date" userID={props.userID} />)
    //   }
    //   setProgressBar(!progressBar);
    // }).catch(err => {
    //   console.log("Error:", err);
    //   setErrorMessage("Please check the network and try again later.")
    //   setDisplayAlert(true)
    // })
  }, []);

  // const setDateCount = (startCount, endCount) => {
  //   setStartDateCount(startCount);
  //   setEndDateCount(endCount);
  // }

  // const updateBadgeCount = () => {
  //   props.updateBadgeCount();
  // };

  const tabClick = (type) => {
    setSelectedTab(type);
    var dataType;
    // var response;
    switch (type) {
      case "Start Date":
        dataType = "Confirmed";
        setApprovalTableData(
          <ApprovalTable
            title="Start Date"
            userID={props.userID}
            updateBadgeCount={props.updateBadgeCount}
            setStartDateCount={setStartDateCount}
            setEndDateCount={setEndDateCount}
          />
        );
        break;
      case "End Date":
        dataType = "Confirmed";
        setApprovalTableData(
          <ApprovalTable
            title="End Date"
            userID={props.userID}
            updateBadgeCount={props.updateBadgeCount}
            setStartDateCount={setStartDateCount}
            setEndDateCount={setEndDateCount}
          />
        );
        break;
      default:
        break;
    }
  };

  const handleAlert = () => {
    setDisplayAlert(!displayAlert);
    setErrorMessage("");
  };

  //tab view
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const handleRefreshPage = () => {
    tabClick(selectedTab)
  }
  return (
    <div className={style.root}>
      <Grid container >
        <Grid item xs={6}>
        </Grid>
        
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={1}>
          <Button
            className={style.refreshIconGrid}
            onClick={handleRefreshPage}
          >
            <RefreshIcon
              className={style.refreshIcon}
            >
            </RefreshIcon>
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Box className={style.badgeCountBox}>
            Start Date : {startDateCount}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={style.badgeCountBox}>
            End Date : {endDateCount}
          </Box>
        </Grid>
      </Grid>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {/* <Badge
          badgeContent={startDateCount}
          max={999}
          color="secondary"
        > */}
        <Tab label="Start Date" onClick={() => tabClick("Start Date")}></Tab>
        {/* </Badge>
        <Badge
          badgeContent={endDateCount}
          max={999}
          color="secondary"
        >  */}
        <Tab label="End Date" onClick={() => tabClick("End Date")} />
        {/* </Badge> */}
        <Tab label="Settings" />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        style={{
          backgroundColor:
            selectedTab === "Start Date" ? color.orange : color.blue,
        }}
      >
        {approvalTableData}
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        style={{
          backgroundColor:
            selectedTab === "End Date" ? color.orange : color.blue,
        }}
      >
        {approvalTableData}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApprovalSetting userID={props.userID} />
      </TabPanel>
      <SystemAlert
        open={displayAlert}
        handleAlert={handleAlert}
        message={errorMessage}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  badgeCountBox: {
    color: color.white,
    fontSize: 16,
    height: "100%",
    width: "70%",
    paddingLeft: 15,
    paddingTop: 15,
    paddingRight: 5,
    paddingBottom: 10,
    backgroundColor: color.blue,
    "&:hover": {
      backgroundColor: color.blue,
    },
  },
  refreshIconGrid: {
    display: "flex",
    alignContent: "flex-start",
    // marginTop: "2%",
    // marginLeft: "95%",
    // marginBottom: "1%",
    width: "4%",
    boxShadow: 5,
  },
  refreshIcon: {
    fontSize: "45px",
  },
  // refreshIconButton:
  // {
  //   backgroundColor: color.white,
  // },
});
