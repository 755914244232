import React, { Fragment, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  AppBar,
  Typography,
  InputBase,
  Toolbar,
  Button,
  Badge,
  Grid,
  Switch,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { color } from "../assets/color";
import CandidateModal from "./CandidateModal";
import Moment from "moment";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { ExportCSV } from "./ExportCSV";
import RefreshIcon from '@material-ui/icons/Refresh';

export default function CandidateTable(props) {
  const style = useStyles();
  const fileName = "V Engage Candidate Data";
  const [candidateModal, setCandidateModal] = useState(<></>);
  // const [candidateData, setCandidateData] = useState({
  // name:"ABC",
  // email:"abc@xyz.com",
  // phone:"123456",
  // startDate:"123",
  // endDate:"456"
  // });
  const [search, setSearch] = useState("");
  const [candidateList, setCandidateList] = useState(props.candidateList);
  const [endDateDownArrow, setEndDateDownArrow] = useState(false);
  const [startDateDownArrow, setStartDateDownArrow] = useState(false);
  const [nameDownArrow, setNameDownArrow] = useState(false);
  // const [loggedInDownArrow, setLoggedInDownArrow] = useState(false);
  const [plcCodeDownArrow, setPlcCodeDownArrow] = useState(false);
  const [loggedInSwitch, setLoggedInSwitch] = useState(false);
  // var modal = <></>;
  const handleClick = (event, candidate) => {
    // setCandidateModal(true);
    // setCandidateData(candidate);

    // console.log("modal")
    setCandidateModal(
      <CandidateModal
        userID={props.userID}
        candidate={candidate}
        open={true}
        handleClose={handleClose}
        type={props.title}
        handleMarkActive={props.handleMarkActive}
        handleMarkAbsconded={props.handleMarkAbsconded}
        handleMarkAlumni={props.handleMarkAlumni}
        handleAbscondedToConfirmed={props.handleAbscondedToConfirmed}
        handleActiveToConfirmed={props.handleActiveToConfirmed}
        handleAlumniToActive={props.handleAlumniToActive}
        handleAbscondedToBlackListed={props.handleAbscondedToBlackListed}
        handleBenchedtoActive={props.handleBenchedtoActive}
      />
    );
  };

  const handleClose = () => {
    // setCandidateModal(!candidateModal);
    setCandidateModal(<></>);
  };

  const StyledTableRow = withStyles((theme) => ({}))(TableRow);

  const sortStartDate = () => {
    var sortedList = candidateList;
    if (startDateDownArrow) {
      sortedList.sort((a, b) => {
        return (
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        );
      });
    } else {
      sortedList.sort((a, b) => {
        return (
          new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
        );
      });
    }
    setCandidateList(sortedList);
    setStartDateDownArrow(!startDateDownArrow);
  };

  const sortEndDate = () => {
    var sortedList = candidateList;
    if (!endDateDownArrow) {
      sortedList.sort((a, b) => {
        return new Date(a.actual_end_date).getTime() - new Date(b.actual_end_date).getTime();
      });
    } else {
      sortedList.sort((a, b) => {
        return new Date(b.actual_end_date).getTime() - new Date(a.actual_end_date).getTime();
      });
    }
    setCandidateList(sortedList);
    setEndDateDownArrow(!endDateDownArrow);
  };

  const sortName = () => {
    var sortedList = candidateList;
    if (!nameDownArrow) {
      sortedList.sort((a, b) => {
        if (a.candidate_name > b.candidate_name) {
          return -1;
        }
        if (b.candidate_name > a.candidate_name) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList.sort((a, b) => {
        if (a.candidate_name > b.candidate_name) {
          return 1;
        }
        if (b.candidate_name > a.candidate_name) {
          return -1;
        }
        return 0;
      });
    }
    setCandidateList(sortedList);
    setNameDownArrow(!nameDownArrow);
  };

  // const sortLoggedIn = () => {
  //   var sortedList = candidateList;
  //   if (!loggedInDownArrow) {
  //     sortedList.sort((a, b) => {
  //       if (a.isNewUser > b.isNewUser) {
  //         return -1;
  //       }
  //       if (b.isNewUser > a.isNewUser) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //   } else {
  //     sortedList.sort((a, b) => {
  //       if (a.isNewUser > b.isNewUser) {
  //         return 1;
  //       }
  //       if (b.isNewUser > a.isNewUser) {
  //         return -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   setCandidateList(sortedList);
  //   setLoggedInDownArrow(!loggedInDownArrow);
  // };

  var numberOfDaysToBeAdded = 3;

  const sortPlcCode = () => {
    var sortedList = candidateList;
    if (!plcCodeDownArrow) {
      sortedList.sort((a, b) => {
        if (a.plc_code > b.plc_code) {
          return -1;
        }
        if (b.plc_code > a.plc_code) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList.sort((a, b) => {
        if (a.plc_code > b.plc_code) {
          return 1;
        }
        if (b.plc_code > a.plc_code) {
          return -1;
        }
        return 0;
      });
    }
    setCandidateList(sortedList);
    setPlcCodeDownArrow(!plcCodeDownArrow);
  };

  // useEffect(() => {
  //     console.log("Use Effect", props.candidateList)
  //     setTimeout(() => {
  //         setCandidateList(props.candidateList)
  //         console.log('This will run after 1 second!', props.candidateList)
  //     }, 1000);
  // }, [])

  // //Switch to show the users not logged into the app
  // const IOSSwitch = withStyles((theme) => ({
  //   root: {
  //     width: 42,
  //     height: 26,
  //     padding: 0,
  //     margin: theme.spacing(1)
  //   },
  //   switchBase: {
  //     padding: 1,
  //     "&$checked": {
  //       transform: "translateX(16px)",
  //       color: theme.palette.common.white,
  //       "& + $track": {
  //         backgroundColor: "#f5b337",
  //         opacity: 1,
  //         border: "none"
  //       }
  //     }
  //   },
  //   thumb: {
  //     width: 24,
  //     height: 24
  //   },
  //   track: {
  //     borderRadius: 26 / 2,
  //     border: `1px solid ${theme.palette.grey[400]}`,
  //     backgroundColor: theme.palette.grey[50],
  //     opacity: 1,
  //     transition: theme.transitions.create(["background-color", "border"])
  //   },
  //   checked: {},
  //   focusVisible: {}
  // }))(({ classes, ...props }) => {
  //   return (
  //     <Switch
  //       focusVisibleClassName={classes.focusVisible}
  //       disableRipple
  //       classes={{
  //         root: classes.root,
  //         switchBase: classes.switchBase,
  //         thumb: classes.thumb,
  //         track: classes.track,
  //         checked: classes.checked
  //       }}
  //     />
  //   );
  // });

  const handleChange = (event) => {
    setLoggedInSwitch(event.target.checked);
    var usersList = props.candidateList.filter((candidateData) =>
      event.target.checked ? candidateData.isNewUser === 1 : true
    );
    console.log(props.title, usersList.length);
    props.updateCount(props.title, usersList.length);
  };

  // const handleRefreshPage = () => {
  //   setProgressBar(true);
  //   var status = ["Confirmed", "Absconded", "Active", "Benched", "Alumni"];
  //   status.map((element) => {
  //     let data = {
  //       user_id: props.userID,
  //       type: element,
  //     };
  //     //console.log(data)
  //     sendPostRequest(Urls.viewCandidate, data)
  //       .then((response) => {
  //         // console.log('Response ', response)
  //         if (!response) {
  //           setErrorMessage("Something went wrong. Please try again later.");
  //           setDisplayAlert(true);
  //         } else {
  //           var candidateList = response.data;
  //           if (element === "Active") {
  //             var filteredData;
  //             if (candidateList) {
  //               filteredData = candidateList.filter((plcFilteredData) =>
  //                 (plcFilteredData.plc_code.includes('PLC'))
  //               )
  //             }
  //             setActiveCount(candidateList ? filteredData.length : 0);
  //             let data = {
  //               user_id: props.userID,
  //               type: "Benched",
  //             };
  //             // console.log(data)
  //             sendPostRequest(Urls.viewCandidate, data)
  //               .then((response) => {
  //                 // console.log('Response ', response)
  //                 if (!response) {
  //                   setErrorMessage(
  //                     "Something went wrong. Please try again later."
  //                   );
  //                   setDisplayAlert(true);
  //                 } else {
  //                   if (response.data) {
  //                     response.data.map((element) => {
  //                       // console.log(response.data)
  //                       if (!element.isBenched) {
  //                         // console.log(element)
  //                         candidateList.push(element);
  //                       }
  //                     });
  //                   }
  //                   setProgressBar(false);
  //                 }
  //               })
  //               .catch((err) => {
  //                 // console.log("Error:", err);
  //                 setErrorMessage(
  //                   "Please check the network and try again later."
  //                 );
  //                 setDisplayAlert(true);
  //                 setProgressBar(false);
  //               });
  //           } else {
  //             if (element + " Candidates" === selectedCandidate) {
  //               setCandidateTable(
  //                 <CandidateTable
  //                   userID={props.userID}
  //                   candidateList={response.data}
  //                   title={element + " Candidates"}
  //                   handleMarkActive={handleMarkActive}
  //                   handleMarkAbsconded={handleMarkAbsconded}
  //                   handleMarkAlumni={handleMarkAlumni}
  //                   handleAbscondedToConfirmed={handleAbscondedToConfirmed}
  //                   handleActiveToConfirmed={handleActiveToConfirmed}
  //                   handleAlumniToActive={handleAlumniToActive}
  //                   handleAbscondedToBlackListed={handleAbscondedToBlackListed}
  //                   handleBenchedtoActive={handleBenchedtoActive}
  //                   updateCount={updateCount}
  //                 />
  //               );
  //             }
  //             var filteredData;
  //             if (response.data) {
  //               filteredData = response.data.filter((plcFilteredData) =>
  //                 (plcFilteredData.plc_code.includes('PLC'))
  //               )
  //             }
  //             var count = response.data ? filteredData.length : 0;
  //             switch (element) {
  //               case "Confirmed":
  //                 setConfirmCount(count);
  //                 break;
  //               case "Absconded":
  //                 setAbscondedCount(count);
  //                 break;
  //               case "Benched":
  //                 setBenchedCount(count);
  //                 break;
  //               case "Alumni":
  //                 setAlumniCount(count);
  //                 break;
  //               default:
  //                 setConfirmCount(count);
  //             }
  //             setProgressBar(false);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         //console.log("Error:", err);
  //         setErrorMessage("Please check the network and try again later.");
  //         setDisplayAlert(true);
  //         setProgressBar(false);
  //       });
  //   });
  // }

  return (
    <Fragment>
      {/* {console.log(props.candidateList)}
            {console.log(candidateList)} */}
      <AppBar position="static" className={style.appbar}>
        <Toolbar>
          {/* <Typography className={style.title} variant="h6">
            {props.title}
          </Typography> */}
          <Typography className={style.title}>Users not on App</Typography>
          <Switch
            color="secondary"
            label="Users not on App"
            onChange={handleChange}
          />
          {candidateList && (
            <ExportCSV
              csvData={candidateList.filter(
                (candidateData) =>
                  (candidateData.candidate_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                    candidateData.email_ID
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    candidateData.plc_code
                      .toLowerCase()
                      .includes(search.toLowerCase())) &&
                  (loggedInSwitch ? candidateData.isNewUser === 1 : true)
              )}
              fileName={fileName}
            />
          )}
          {/* <Button variant="contained" className={style.exportButton}>
            <CSVLink data={candidateList} filename={fileName}>Export</CSVLink>
          </Button> */}
          <div className={style.search}>
            <div className={style.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: style.searchText,
                input: style.searchBox,
              }}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        </Toolbar>
        {/* <Toolbar>
          <Typography className={style.notLoggedUsers} >
            Users not on App
          </Typography>
          <Switch
            color="secondary"
          // onChange={handleChange}
          />
        </Toolbar> */}
        <Table className={style.table}>
          <TableHead>
            <TableRow>
              <TableCell className={style.tableHead}>
                <div>
                  PLC Code
                  {plcCodeDownArrow ? (
                    <ArrowDropDown onClick={(event) => sortPlcCode()} />
                  ) : (
                      <ArrowDropUp onClick={(event) => sortPlcCode()} />
                    )}
                </div>
              </TableCell>
              <TableCell className={style.tableHead}>
                <div>
                  Name
                  {nameDownArrow ? (
                    <ArrowDropDown onClick={(event) => sortName()} />
                  ) : (
                      <ArrowDropUp onClick={(event) => sortName()} />
                    )}
                </div>
              </TableCell>
              <TableCell className={style.tableHead}>Email</TableCell>
              <TableCell className={style.tableHead}>Phone</TableCell>
              <TableCell className={style.tableHead}>
                <div>
                  Start Date
                  {startDateDownArrow ? (
                    <ArrowDropDown onClick={(event) => sortStartDate()} />
                  ) : (
                      <ArrowDropUp onClick={(event) => sortStartDate()} />
                    )}
                </div>
              </TableCell>
              <TableCell className={style.tableHead}>
                <div>
                  End Date
                  {endDateDownArrow ? (
                    <ArrowDropDown onClick={(event) => sortEndDate()} />
                  ) : (
                      <ArrowDropUp onClick={(event) => sortEndDate()} />
                    )}
                </div>
              </TableCell>
              <TableCell className={style.tableHead}>Pass Through</TableCell>
              {/* <TableCell className={style.tableHead}>
                <div>
                  Logged In
                {loggedInDownArrow ? (
                    <ArrowDropDown onClick={(event) => sortLoggedIn()} />
                  ) : (
                      <ArrowDropUp onClick={(event) => sortLoggedIn()} />
                    )}
                </div>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {candidateList &&
              candidateList
                .filter(
                  (candidateData) =>
                    (candidateData.candidate_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                      candidateData.email_ID
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      candidateData.plc_code
                        .toLowerCase()
                        .includes(search.toLowerCase())) &&
                    (loggedInSwitch ? candidateData.isNewUser === 1 : true)
                )
                .map((candidate) => (
                  <StyledTableRow
                    className={style.cell}
                    style={{
                      backgroundColor: candidate.isBlacklisted
                        ? color.darkGrey
                        : candidate.candidate_status === "Benched"
                          ? color.lightRed
                          : "none",
                      color: candidate.isBlacklisted
                        ? color.white
                        : color.black,
                    }}
                    onClick={(event) => handleClick(event, candidate)}
                    key={candidate.plc_code}
                  >
                    <TableCell>
                      <Button style={{ textTransform: "none" }}>
                        {candidate.plc_code}
                      </Button>
                      {/* {candidate.isNewUser ?
                        <Button style={{ textTransform: "none" }}>
                          {candidate.plc_code}
                        </Button> :
                        <Badge variant="dot" color="primary">
                          <Button style={{ textTransform: "none" }}>
                            {candidate.plc_code}
                          </Button>
                        </Badge>} */}
                    </TableCell>
                    <TableCell>
                      {/* {console.log(new Date(candidate.status_modified_at))}
                      {console.log(
                        new Date(
                          Moment(new Date(candidate.status_modified_at)).add(
                            numberOfDaysToBeAdded,
                            "days"
                          )
                        )
                      )} */}
                      {/* {console.log(
                        new Date(
                          Moment(new Date(candidate.status_modified_at)).add(
                            numberOfDaysToBeAdded,
                            "days"
                          )
                        ).getFullYear()
                      )}
                      {console.log(
                        new Date(
                          Moment(new Date(candidate.status_modified_at)).add(
                            numberOfDaysToBeAdded,
                            "days"
                          )
                        ).getMonth()
                      )}
                      {console.log(
                        new Date(
                          Moment(new Date(candidate.status_modified_at)).add(
                            numberOfDaysToBeAdded,
                            "days"
                          )
                        ).getDate()
                      )} */}
                      {/* {console.log(Moment(new Date()).format("MM/DD/YYYY") <= Moment(new Date(candidate.status_modified_at)).add(numberOfDaysToBeAdded).format("MM/DD/YYYY"))}
                     {console.log(Moment(new Date()).format("MM/DD/YYYY"))}
                     {console.log(Moment(new Date(candidate.status_modified_at)).add(numberOfDaysToBeAdded).format("MM/DD/YYYY"))} */}
                      {new Date().getFullYear() <=
                        new Date(
                          Moment(new Date(candidate.status_modified_at)).add(
                            numberOfDaysToBeAdded,
                            "days"
                          )
                        ).getFullYear() ? (
                          new Date().getMonth() <=
                            new Date(
                              Moment(new Date(candidate.status_modified_at)).add(
                                numberOfDaysToBeAdded,
                                "days"
                              )
                            ).getMonth() ? (
                              new Date().getDate() <=
                                new Date(
                                  Moment(new Date(candidate.status_modified_at)).add(
                                    numberOfDaysToBeAdded,
                                    "days"
                                  )
                                ).getDate() ? (
                                  <Badge variant="dot" color="secondary">
                                    <Button style={{ textTransform: "none" }}>
                                      {candidate.candidate_name}
                                    </Button>
                                  </Badge>
                                ) : (
                                  <Button
                                    style={{
                                      textTransform: "none",
                                      alignContent: "left",
                                    }}
                                  >
                                    {candidate.candidate_name}
                                  </Button>
                                )
                            ) : (
                              <Button
                                style={{
                                  textTransform: "none",
                                  alignContent: "left",
                                }}
                              >
                                {candidate.candidate_name}
                              </Button>
                            )
                        ) : (
                          <Button
                            style={{
                              textTransform: "none",
                              alignContent: "left",
                            }}
                          >
                            {candidate.candidate_name}
                          </Button>
                        )}
                    </TableCell>
                    <TableCell>{candidate.email_ID}</TableCell>
                    <TableCell>
                      {candidate.phone ? candidate.phone : "N/A"}
                    </TableCell>
                    <TableCell>
                      {candidate.start_date
                        ? Moment(new Date(candidate.start_date)).format(
                          "MM/DD/YYYY"
                        )
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {candidate.actual_end_date
                        ? Moment(new Date(candidate.actual_end_date)).format(
                          "MM/DD/YYYY"
                        )
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {candidate.isPassThrough ? "Yes" : "No"}
                    </TableCell>
                    {/* <TableCell>
                      {candidate.isNewUser ? "No" : "Yes"}
                    </TableCell> */}
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        {candidateModal}
      </AppBar>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  //table
  table: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: color.white,
    color: color.blue,
  },
  flexDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  cell: {
    backgroundColor: color.white,
    fontSize: 12,
    width: "100%",
    align: "left",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  //App bar with search icon
  appbar: {
    backgroundColor: color.white,
    width: "100%",
    marginLeft: "3%",
    alignItems: "left",
  },
  title: {
    color: color.red,
  },
  // notLoggedUsers: {
  //   color: color.orange
  // },
  exportButton: {
    position: "absolute",
    marginLeft: "60%",
    width: "10%",
    color: color.white,
    backgroundColor: color.orange,
    "&:hover": {
      backgroundColor: color.orange,
    },
  },
  search: {
    position: "absolute",
    marginLeft: "76%",
    width: "20%",
  },
  searchIcon: {
    padding: "3%",
    height: "100%",
    width: "20%",
    backgroundColor: color.grey,
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    marginLeft: "-13%",
    justifyContent: "center",
  },
  searchText: {
    color: color.black,
  },
  searchBox: {
    width: "100%",
    borderRadius: 10,
    marginLeft: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: color.grey,
  },
  badge: {
    color: "green",
  },
  switch: {
    color: color.orange,
  },
  refreshIconGrid: {
    display: "flex",
    alignContent: "flex-start",
    // marginTop: "2%",
    marginLeft: "95%",
    marginBottom: "1%",
    width: "4%",
    boxShadow: 5,

  },
  refreshIcon: {
    fontSize: "45px",
  },
  // refreshIconButton:
  // {
  //   backgroundColor: color.white,
  // },
}));
