import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, TableRow, TableCell } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import { color } from '../assets/color';
import Moment from 'moment';

export default function ApprovalModal(props) {
    const style = useStyles()

    return (
        < Dialog open={props.open} >
            <DialogTitle className={style.dialogTitle} >
                <Typography >Candidate Details</Typography>
                <IconButton
                    className={style.closeButton}
                    onClick={props.handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    <TableRow ><TableCell className={style.dialogContentLeft} >Name : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.candidate_name}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft} >Email : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.email_ID}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft} >Phone : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.phone}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>Client : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.client_details}</TableCell>
                    </TableRow>
                    {/* <TableRow ><TableCell className={style.dialogContentLeft}>Recruiter (ID) : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.approval.recruiter_id !== "N/A" ? color.blue : color.grey) }}> {props.approval.recruiter_id}</TableCell>
                    </TableRow> */}
                    <TableRow ><TableCell className={style.dialogContentLeft}>Recruiter : </TableCell>
                        <TableCell className={style.dialogContentRight} > {props.approval.recruiter}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>Job Title : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.job_title}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>Tax Terms : </TableCell>
                        <TableCell className={style.dialogContentRight} > {props.approval.tax_terms}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>PLC Code : </TableCell>
                        <TableCell className={style.dialogContentRight}> {props.approval.plc_code}</TableCell>
                    </TableRow>
                    {/* <TableRow ><TableCell className={style.dialogContentLeft}>Primary Skills : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.approval.primary_skills ? color.blue : color.grey) }}> {props.approval.primary_skills ? props.approval.skills : "N/A"}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>Domain : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.approval.candidate_domain ? color.blue : color.grey) }}> {props.approval.domain ? props.approval.domain : "N/A"}</TableCell>
                    </TableRow> */}
                    <TableRow ><TableCell className={style.dialogContentLeft}>Start Date : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.approval.start_date ? color.blue : color.grey) }}> {props.approval.start_date ? Moment(props.approval.start_date).format("MM/DD/YYYY") : "N/A"}</TableCell>
                    </TableRow>
                    <TableRow ><TableCell className={style.dialogContentLeft}>End Date : </TableCell>
                        <TableCell className={style.dialogContentRight} style={{ color: (props.approval.actual_actual_end_date ? color.blue : color.grey) }}> {props.approval.actual_end_date ? Moment(props.approval.actual_end_date).format("MM/DD/YYYY") : "N/A"}</TableCell>
                    </TableRow>
                </Typography>
            </DialogContent>
        </Dialog >
    )
}

const useStyles = makeStyles((theme) => ({
    //modal view
    dialogTitle: {
        color: color.red,
        variant: "h6"
    },
    dialogContentLeft: {
        color: color.black,
        borderBottom: "none",
        padding: theme.spacing(1),
    },
    dialogContentRight: {
        color: color.blue,
        borderBottom: "none",
        padding: theme.spacing(1),
    },
    blueButton: {
        backgroundColor: color.blue,
        variant: "contained",
        color: color.white,
        padding: theme.spacing(1)
    },
    orangeButton: {
        backgroundColor: color.orange,
        variant: "contained",
        color: color.white
    },
    closeButton: {
        color: theme.palette.grey[500],
        float: "right",
        top: '-30px'
    }
}));