import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { color } from "../assets/color";

export default function SystemAlert(props) {
    const style = useStyles();

    return (
        <div>
            <Dialog open={props.open} >
                <DialogContent>
                    <Typography gutterBottom>{props.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={props.handleAlert} className={style.blueButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    blueButton: {
        color: color.blue,
    },

}))