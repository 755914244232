import React from "react";
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { color } from "../assets/color";


export default function ProgressBar() {
    const style = useStyles();

    return (
        <Dialog
            open={true}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
        >
            <DialogContent style={{ overflow: "hidden" }}>
                <CircularProgress size={70} className={style.progress} />
            </DialogContent>
            

        </Dialog> 
    );
}

const useStyles = makeStyles((theme) => ({
    progress: {
        color: color.orange,
        // marginTop: "30vh",
        // marginLeft: "80vh",
        // marginBottom: "30vh",
    },
}))